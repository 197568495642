import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { auth } from '../auth';

const PublicRoute = props => {
  const {layout: Layout, component: Component, title, ...rest } = props;

  return (
    <Route
      {...rest}
      render={
        (props) => {
            if (auth().authenticated) {
              return <Redirect to={
                {
                    pathname: "/dashboard",
                    state: {
                        from: props.location
                    }
                }
            } />
            } else {
              return <Layout>
                    <Component title={title} {...props} />
                </Layout>;
            }
        }
    }/>
  );
};

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default PublicRoute;
