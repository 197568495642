import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import ExpandMore from '@material-ui/icons/ExpandMore';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { withStyles } from '@material-ui/core/styles';



import { Link } from 'react-router-dom';


import { signout } from '../../../auth';
import { useHistory } from "react-router-dom";

const StyledBadge = withStyles((theme) => ({
  badge: {
    //  color: theme.palette.white,
    // backgroundColor: theme.palette.pink,
  },
}))(Badge);

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({

  appBar: {
    //backgroundColor: 'rgba(5,255,5, 0.35)',
    width: `calc(100% - 64px)`,
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  grow: {
    flexGrow: 1,
  },

  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    display: 'none',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  hide: {
    display: 'none'
  },

  profileButton: {
    //   color: theme.palette.mustard,
    // border: 'none',
    //  textDecoration: 'none',
    textTransform: 'none',
    // padding: '0px 5px',
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    color: theme.palette.info.light,
    borderColor: theme.palette.info.light,

    '&:hover': {
      color: theme.palette.common.white,
       borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    }
  },

  logoutButton: {
    //   color: theme.palette.mustard,
    // border: 'none',
    //  textDecoration: 'none',
    textTransform: 'none',
    // padding: '0px 5px',
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,

    '&:hover': {
       color: theme.palette.common.white,
       borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    }
  },

   
}));



const Topbar = ({ title, onSidebarOpen, user }) => {



  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);



  const handleProfileButton = () => {
    history.push('/profile');
  };

  const handleLogoutButton = () => {
    signout();
    history.push('/login');
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  
  const menuId = 'primary-search-account-menu';


  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={Link} to="/profile">
      <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{ user.name }</p>
      </MenuItem>

      <MenuItem onClick={handleLogoutButton}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar position="absolute" className={clsx(classes.appBar, onSidebarOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>

          <Typography className={classes.title} variant="h6" noWrap>
            {title}
          </Typography>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search ..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>

          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>

            

          <Button
              variant="outlined"
              size="small"
              className={classes.profileButton}
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileButton}
              startIcon={<AccountCircle />}
            > { user.name } </Button>  

<Button
              variant="outlined"
              size="small"
              className={classes.logoutButton}
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleLogoutButton}
              endIcon={<ExitToAppIcon />}
            >  Logout </Button>  

          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
  
    </div>
  );
}

export default Topbar;