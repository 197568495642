import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";

import { Formik } from "formik";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";


import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: 16,
  },
}));

// const ImageSchema = Yup.object().shape({
//   description: Yup.string().required("required"),
//   image: Yup.mixed().required(),
// });

export default function ProductShippingEditForm({ productId, productTitle, productShippingZones, productShippingOptions, setLoadProductShipping}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [previewImage, setPreviewImage] = React.useState(null);

  const [loading, setLoading] = React.useState(false);



  const [
    availableShippingZones,
    setAvailableShippingZones,
  ] = React.useState([]);
  const [
    availableShippingOptions,
    setAvailableShippingOptions,
  ] = React.useState([]);


  React.useEffect(() => {
  

      // available shipping packages
axios
.get(`available-shipping-zones`)
.then((response) => {
  setAvailableShippingZones(response.data.data);
}).then((response) => {
  // available shipping options
axios
.get(`available-shipping-options`)
.then((response) => {
  setAvailableShippingOptions(response.data.data);
});
})
.catch((error) => {
  console.log(error);
});
    }, []);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
  shipping_zones: productShippingZones,
  shipping_options: productShippingOptions,
  };

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        onClick={handleClickOpen}
      >
        <EditIcon fontSize="small" />
      </IconButton>

      <Formik
        initialValues={initialValues}
        //  validationSchema={ImageSchema}
        onSubmit={(values, actions) => {
        //  setLoading(true);

          console.log(values);

          values.shipping_zones = 

          axios
            .put(`products/${productId}/shipping`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                setLoadProductShipping(true);
               console.log('test');
              }
            })
            .catch(function (error) {
              console.log(error.response);
              setLoading(false);
              // actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{productTitle} Shipping</DialogTitle>
            <DialogContent dividers>
              <DialogContentText></DialogContentText>

              <FormControl fullWidth className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="shipping_zones"
                  name="shipping_zones"
                  style={{ minWidth: 400 }}
                  options={availableShippingZones}
                  defaultValue={values.shipping_zones}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, value) =>
                    option.id === value.id
                  }

                  onChange={(e, value) => {
                    setFieldValue("shipping_zones", value);
                  }}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Available Shipping Zones"
                      helperText={errors ? errors.shipping_zones : ""}
                      onBlur={handleBlur}
                      error={
                        errors &&
                        errors.shipping_zones &&
                        touched.shipping_zones
                          ? true
                          : null
                      }
                    />
                  )}
                />
              </FormControl>


              <FormControl fullWidth className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="shipping_options"
                  name="shipping_options"
                  style={{ minWidth: 400 }}
                  options={availableShippingOptions}
                  defaultValue={values.shipping_options}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.provider+' - '+option.service+' ('+option.speed+')'}
                  getOptionSelected={(option, value) =>
                    option.id === value.id
                  }

                  onChange={(e, value) => {
                    setFieldValue("shipping_options", value);
                  }}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Available Shipping Options"
                      helperText={errors ? errors.shipping_options : ""}
                      onBlur={handleBlur}
                      error={
                        errors &&
                        errors.shipping_options &&
                        touched.shipping_options
                          ? true
                          : null
                      }
                    />
                  )}
                />
              </FormControl>
              



              

</DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <EditIcon />
                }
              >
                {isSubmitting ? "Updating ..." : "Update"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
