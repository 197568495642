import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

import Select from "@material-ui/core/Select";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
}));

const costBasedOnOptions = [
  { code: "basket_weight", label: "Basket Weight" },
  { code: "basket_value", label: "Basket Value" },
];

const ShippingRateSchema = Yup.object().shape({
  package_size_id: Yup.number().required("Shipping package is required."),
  shipping_option_id: Yup.number().required("Shipping option is required."),
  cost_based_on: Yup.string().required("Cost based on is required."),
  min_value: Yup.number().when("cost_based_on", {
    is: (value) =>
      value &&
      value === "basket_value",
    then: Yup.number()
      .required("Minimum value is required")
      .typeError("Minimum value must be a number"),
    otherwise: Yup.number().typeError("Minimum value must be a number"),
  }),
  max_value: Yup.number().when("cost_based_on", {
    is: (value) =>
      value &&
      value === "basket_value",
    then: Yup.number()
      .required("Maximum value is required")
      .positive()
      .typeError("Maximum value must be a positive number"),
    otherwise: Yup.number()
      .positive()
      .typeError("Maximum value must be a positive number"),
  }),
  min_weight: Yup.number().when("cost_based_on", {
    is: (value) =>
      value &&
      value === "basket_weight",
    then: Yup.number()
      .required("Minimum weight is required")
      .typeError("Minimum weight must be a number"),
    otherwise: Yup.number().typeError("Minimum weight must be a number"),
  }),
  max_weight: Yup.number().when("cost_based_on", {
    is: (value) =>
      value &&
      value === "basket_weight",
    then: Yup.number()
      .required("Maximum value is required")
      .positive()
      .typeError("Maximum value must be a positive number"),
    otherwise: Yup.number()
      .positive()
      .typeError("Maximum value must be a positive number"),
  }),
  cost: Yup.number().required("Cost is required."),
  max_cover_amount: Yup.number().when("cover_damage", {
    is: (value) => value && value === 1,
    then: Yup.number()
      .required("Maximum damage cover value is required")
      .positive()
      .typeError("Maximum damage cover value must be a positive number"),
    otherwise: Yup.number(),
  }),
});

export default function ShippingRateEditForm({
  rate,
  updateShippingRate,
  availableShippingPackages,
  availableShippingOptions,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [costBasedOnOption, setCostBasedOption] = React.useState("");
  const [coverParcelDamage, setCoverParcelDamage] = React.useState(rate.cover_damage === 1 ? true : false);

  const handleClickOpen = (setFieldValue) => {
    setOpen(true);
    setCostBasedOption(rate.cost_based_on);
    setFieldValue("package_size_id", rate.package_size_id);
    setFieldValue("shipping_option_id", rate.shipping_option_id);
    setFieldValue("cost_based_on", rate.cost_based_on);
    setFieldValue("available", rate.available);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    package_size_id: rate.package_size_id,
    shipping_option_id: rate.shipping_option_id,
    cost_based_on: rate.cost_based_on,
    min_value: rate.min_value,
    max_value: rate.max_value > 0 ? rate.max_value : "",
    min_weight: rate.min_weight,
    max_weight: rate.max_weight > 0 ? rate.max_weight : "",
    cost: rate.cost,
    available: rate.available,
    note: rate.note,
  };

  console.log(initialValues);

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={ShippingRateSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .put(`shipping-zones/${rate.shipping_zone_id}/rates/${rate.id}`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              //   actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                updateShippingRate(response.data.data);
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <React.Fragment>
            <IconButton
              aria-label="edit"
              onClick={() => handleClickOpen(setFieldValue)}
            >
              <EditIcon fontSize="small" />
            </IconButton>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Edit Shipping Rate
              </DialogTitle>

              <DialogContent dividers={true}>
                <FormControl fullWidth className={classes.formControl}>
                  <Autocomplete
                    name="package_size_id"
                    defaultValue={availableShippingPackages.find(
                      (item) => item.id === rate.package_size_id
                    )}
                    options={availableShippingPackages.map((item) => item)}
                    getOptionLabel={(option) =>
                      option.format +
                      " - (" +
                      option.length +
                      "x" +
                      option.width +
                      "x" +
                      option.height +
                      " cm) - (" +
                      option.min_weight +
                      "-" +
                      option.max_weight +
                      " gm)"
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, value) => {
                      setFieldValue("package_size_id", value.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Package"
                        helperText={errors ? errors.package_size_id : ""}
                        onBlur={handleBlur}
                        error={
                          errors &&
                          errors.package_size_id &&
                          touched.package_size_id
                            ? true
                            : null
                        }
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <Autocomplete
                    name="shipping_option_id"
                    defaultValue={availableShippingOptions.find(
                      (item) => item.id === rate.shipping_option_id
                    )}
                    options={availableShippingOptions.map((item) => item)}
                    getOptionLabel={(option) =>
                      option.provider +
                      " - " +
                      option.service +
                      " (" +
                      option.speed +
                      ") - (" +
                      option.min_weight +
                      "-" +
                      option.max_weight +
                      " gm)"
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, value) => {
                      setFieldValue("shipping_option_id", value.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Shipping Option"
                        helperText={errors ? errors.shipping_option_id : ""}
                        onBlur={handleBlur}
                        error={
                          errors &&
                          errors.shipping_option_id &&
                          touched.shipping_option_id
                            ? true
                            : null
                        }
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <Autocomplete
                    name="cost_based_on"
                    defaultValue={costBasedOnOptions.find(
                      (item) => item.code === values.cost_based_on
                    )}
                    options={costBasedOnOptions}
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionSelected={(option, value) =>
                      option.code === value.code
                    }
                    onChange={(e, value) => {
                      setFieldValue("cost_based_on", value.code);
                      setCostBasedOption(value.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cost Based On"
                        helperText={errors ? errors.cost_based_on : ""}
                        onBlur={handleBlur}
                        error={
                          errors &&
                          errors.cost_based_on &&
                          touched.cost_based_on
                            ? true
                            : null
                        }
                      />
                    )}
                  />
                </FormControl>

                {costBasedOnOption === "basket_value" ? (
                  <React.Fragment>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        name="min_value"
                        label="Minimum Value"
                        type="text"
                        value={values.min_value}
                        helperText={errors ? errors.min_value : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors && errors.min_value && touched.min_value
                            ? true
                            : null
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        name="max_value"
                        label="Maximum Value"
                        type="text"
                        value={values.max_value}
                        helperText={errors ? errors.max_value : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors && errors.max_value && touched.max_value
                            ? true
                            : null
                        }
                      />
                    </FormControl>
                  </React.Fragment>
                ) : null}

                {costBasedOnOption === "basket_weight" ? (
                  <React.Fragment>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        name="min_weight"
                        label="Minimum Weight"
                        type="text"
                        value={values.min_weight}
                        helperText={errors ? errors.min_weight : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors && errors.min_weight && touched.min_weight
                            ? true
                            : null
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        name="max_weight"
                        label="Maximum Weight"
                        type="text"
                        value={values.max_weight}
                        helperText={errors ? errors.max_weight : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors && errors.max_weight && touched.max_weight
                            ? true
                            : null
                        }
                      />
                    </FormControl>
                  </React.Fragment>
                ) : null}

                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    name="cost"
                    label="Cost (£)"
                    type="text"
                    value={values.cost}
                    helperText={errors ? errors.cost : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors && errors.cost && touched.cost ? true : null}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.available === 1 ? true : false}
                        onChange={(e, value) => {
                          setFieldValue("available", value ? 1 : 0);
                        }}
                        name="available"
                        color="primary"
                      />
                    }
                    label="Available"
                  />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    name="note"
                    label="Note"
                    type="text"
                    value={values.note}
                    helperText={errors ? errors.note : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors && errors.note && touched.note ? true : null}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={handleSubmit}
                  startIcon={
                    loading ? <CircularProgress size={16} /> : <SaveIcon />
                  }
                >
                  {isSubmitting ? "Saving ..." : "Save"}
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
}
