import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

import Select from "@material-ui/core/Select";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
}));

const CollectionRateSchema = Yup.object().shape({
  cost: Yup.number().required("Cost is required."),
  speed: Yup.string().required("Speed is required."),
  });

export default function CollectionRateEditForm({ rate, updateCollectionRate }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [costBasedOnOption, setCostBasedOption] = React.useState("");
  const [coverParcelDamage, setCoverParcelDamage] = React.useState(
    rate.cover_damage === 1 ? true : false
  );

  const handleClickOpen = (setFieldValue) => {
    setOpen(true);
    setCostBasedOption(rate.cost_based_on);
    setFieldValue("package_size_id", rate.package_size_id);
    setFieldValue("shipping_option_id", rate.shipping_option_id);
    setFieldValue("cost_based_on", rate.cost_based_on);
    setFieldValue("available", rate.available);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    speed: rate.speed,
    cost: rate.cost,
    available: 0,
    note: "",
  };

  console.log(initialValues);

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={CollectionRateSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .put(
              `collection-points/${rate.collection_point_id}/rates/${rate.id}`,
              values
            )
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              //   actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                updateCollectionRate(response.data.data);
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <React.Fragment>
            <IconButton
              aria-label="edit"
              onClick={() => handleClickOpen(setFieldValue)}
            >
              <EditIcon fontSize="small" />
            </IconButton>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Edit Collection Rate
              </DialogTitle>

              <DialogContent dividers="true">
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    name="speed"
                    label="Speed"
                    type="text"
                    value={values.speed}
                    helperText={errors ? errors.speed : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors && errors.speed && touched.speed ? true : null
                    }
                  />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    name="cost"
                    label="Cost (£)"
                    type="text"
                    value={values.cost}
                    helperText={errors ? errors.cost : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors && errors.cost && touched.cost ? true : null}
                  />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.available === 1 ? true : false}
                        onChange={(e, value) => {
                          setFieldValue("available", value ? 1 : 0);
                        }}
                        name="available"
                        color="primary"
                      />
                    }
                    label="Available"
                  />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    name="note"
                    label="Note"
                    type="text"
                    value={values.note}
                    helperText={errors ? errors.note : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors && errors.note && touched.note ? true : null}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={handleSubmit}
                  startIcon={
                    loading ? <CircularProgress size={16} /> : <SaveIcon />
                  }
                >
                  {isSubmitting ? "Saving ..." : "Save"}
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
}
