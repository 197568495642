import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import PublicIcon from "@material-ui/icons/Public";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
} from "@material-ui/core";

import ShippingZoneAddForm from "./shipping-zone-add-form.component";
import ShippingZoneEditForm from "./shipping-zone-edit-form.component";
import ShippingZoneDeleteForm from "./shipping-zone-delete-form.component";
import ShippingCountries from "./shipping-countries.component";
import ShippingZoneProducts from './shipping-zone-products.component';
import SkeletonCard from "../../common/skeleton.component";

import ShippingRates from "./rates/shipping-rates.component";

import AvailablePackageSizes from "./available-package-sizes.component";
import AvailableShippingOptions from "./available-shipping-options.component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid${grey[300]}`,
  },
  hoverPointer: {
    cursor: "pointer",
    borderRight: `1px solid${grey[300]}`,
  },

  cardHeader: {
    backgroundColor: grey[300],
  },
  avatar: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.info.light,
  },
}));

export default function ShippingZoneDetails({ zoneId }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [shippingZone, setShippingZone] = React.useState([]);
  const [loadShippingZone, setLoadShippingZone] = React.useState(true);

  const [
    availableShippingPackages,
    setAvailableShippingPackages,
  ] = React.useState([]);
  const [
    availableShippingOptions,
    setAvailableShippingOptions,
  ] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    if(loadShippingZone) {
      axios
      .get(`shipping-zones/${zoneId}`)
      .then((response) => {
        setShippingZone(response.data.data);
        setLoadShippingZone(false);
        setLoading(false);
      }).then((response) => {
// available shipping packages
axios
.get(`available-shipping-package-sizes`)
.then((response) => {
  setAvailableShippingPackages(response.data.data);
})
.catch((error) => {
  console.log(error);
});

// available shipping options
axios
.get(`available-shipping-options`)
.then((response) => {
  setAvailableShippingOptions(response.data.data);
})
.catch((error) => {
  console.log(error);
});

      })
      .catch((error) => {
        console.log(error);
        setLoadShippingZone(false);
        setLoading(false);
      });

    

    }
    
  }, [loadShippingZone]);

  console.log(shippingZone);

  const addShippingZone = (zone) => {
    // shippingZones.push(zone);
    // setShippingZones(shippingZones.map((item) => item));
  };

  const updateShippingZone = () => {
    setLoadShippingZone(true);
    // setShippingZones(
    //   shippingZones.map((item) => (item.id !== zone.id ? item : zone))
    // );
  };

  const removeShippingZone = (zoneId) => {
    // setShippingZones(shippingZones.filter((item) => item.id !== zoneId));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return loading ? (
    ""
  ) : (
    <React.Fragment>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar aria-label="shipping zone" className={classes.avatar}>
              <PublicIcon fontSize="large" />
            </Avatar>
          }
          action={
            <ShippingZoneEditForm
              zone={shippingZone}
              updateShippingZone={updateShippingZone}
            />
          }
          subheader={`${
            shippingZone.available === 1
              ? "Shipping available to " +
                shippingZone.no_of_countries +
                (shippingZone.no_of_countries > 0 ? " countries" : " country")
              : "Shipping not available to this zone"
          }`}
          title={
            <div>
              <Typography variant="h6">{`${shippingZone.title}`} </Typography>
            </div>
          }
        />
        <Divider />
        <CardContent>
          <Typography variant="h6" color="textSecondary">
            {`${shippingZone.title}`} Shipping Countries
          </Typography>
          <ShippingCountries shippingZone={shippingZone} updateShippingZone={updateShippingZone}/>
          <Typography
            variant="h6"
            style={{ marginTop: 16 }}
            color="textSecondary"
          >
            Available Package sizes & Shipping Options
          </Typography>
          <AvailablePackageSizes packageSizes={availableShippingPackages} />
          <AvailableShippingOptions
            shippingOptions={availableShippingOptions}
          />

<Typography variant="h6" color="textSecondary"  style={{ marginTop: 16 }}>
            Products available to ship {`${shippingZone.title}`}
          </Typography>
          <ShippingZoneProducts shippingZone={shippingZone} />

          <Typography
            variant="h6"
            style={{ marginTop: 16 }}
            color="textSecondary"
          >
            Shipping rates for {`${shippingZone.title}`} 
          </Typography>
          <ShippingRates
            zoneId={zoneId}
            availableShippingPackages={availableShippingPackages}
            availableShippingOptions={availableShippingOptions}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
