import React, { Component } from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { ProductListNav } from "../../components/products/product-nav.component";
import ProductList from "../../components/products/product-list.component";
import CircularProgress from "../../components/common/circular-progress.component";
import Button from "@material-ui/core/Button";

class ProductListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      products: [],
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    axios
      .get(`products`)
      .then((response) => {
        this.setState({
          loading: false,
          products: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  handleReloadProducts = () => {
    axios
      .get(`products`)
      .then((response) => {
        this.setState({
          loading: false,
          products: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  searchProducts = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value !== "") {
        axios
          .post(`filter-product-list`, { search: e.target.value })
          .then((response) => {
            if (response.data.data.length > 0) {
              this.setState({
                products: response.data.data,
              });
            } else {
              this.setState({
                message: "No product found.",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  render() {
    return this.state.loading ? (
      <CircularProgress />
    ) : (
      <React.Fragment>
        <TextField
          style={{ marginBottom: "12px" }}
          id="search_product"
          name="search_product"
          label="Search Products ..."
          onKeyUp={(e) => this.searchProducts(e)}
        />
        <Button
          variant="contained"
          onClick={this.handleReloadProducts}
          style={{ margin: "8px" }}
        >
          Load All Products
        </Button>
        <ProductListNav />
        <ProductList products={this.state.products} />
      </React.Fragment>
    );
  }
}

export default ProductListPage;
