import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Animated } from 'react-animated-css';

const useStyles = makeStyles((theme) => ({
    depositContext: {
        flex: 1,
    },

    name: {
        color: theme.palette.info.light,
        padding: '10px 0',
        maxWidth: '200px',
        fontWeight: 500,
        fontSize: '1.25rem',
        fontFamily: ["Heebo", "sans-serif"].join(',')

    },
    fun: {
      display: 'inline-block',
      transform: 'rotateZ(-9deg)',
    },

    hide: {
        display: 'none',
    },
    show: {
        display: 'block'
    }
}));

const AppName = ({ open }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.name, !open && classes.hide)}>
            <Animated className="name"  animationIn="pulse" animationOut="shakeY" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                ShopX
            </Animated>
        </div>
    );
}

export default AppName;


