import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import Chip from "@material-ui/core/Chip";
import PublicIcon from "@material-ui/icons/Public";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  table: {
    width: '98%',
  },
  tableHeader: {
      backgroundColor: `${grey[300]}`,
  },
  columnBorder: {
    borderRight: `1px solid ${grey[300]}`,
  },
  chip: {
    padding: "12px 8px",
    marginRight: '8px',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.info.light,
    }
  },
}));

export default function AvailableShippingOptions({ shippingOptions }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>

<Chip
        avatar={<Avatar style={{backgroundColor: 'white'}}>{shippingOptions.length}</Avatar>}
        label={shippingOptions.length > 1 ? 'Available Shipping Options' : 'Available Shipping Option'}
        className={classes.chip}
        onClick={handleClickOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
            maxWidth={"md"}
      >
        <DialogTitle id="scroll-dialog-title">Available Shipping Options</DialogTitle>
        <DialogContent dividers={true}>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>Provider</TableCell>
                  <TableCell className={classes.tableHeader}>Service</TableCell>
                  <TableCell className={classes.tableHeader}>Speed</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Weight (g)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shippingOptions.map((shippingOption) => (
                  <TableRow key={shippingOption.id}>
                    <TableCell component="th" scope="row" className={classes.columnBorder}>
                      {shippingOption.provider}
                    </TableCell>
                    <TableCell className={classes.columnBorder}>{shippingOption.service}</TableCell>
                    <TableCell className={classes.columnBorder}>{shippingOption.speed}</TableCell>
                    <TableCell align="right" className={classes.columnBorder}>{shippingOption.min_weight} - {shippingOption.max_weight}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
