import React from "react";
import Chart from "react-google-charts";

const BarChart = ({ orders }) => {
    return <Chart
    width={'800px'}
    height={'300px'}
    chartType="Bar"
    //loader={<div>Loading Chart</div>}
    data={[
      ['Date', 'Amount'],
      [orders[0]['date']+' ('+orders[0]['orders']+') ',  Number(orders[0]['amount'])],
      [orders[1]['date']+' ('+orders[1]['orders']+') ',  Number(orders[1]['amount'])],
      [orders[2]['date']+' ('+orders[2]['orders']+') ',  Number(orders[2]['amount'])],
      [orders[3]['date']+' ('+orders[3]['orders']+') ',  Number(orders[3]['amount'])],
      [orders[4]['date']+' ('+orders[4]['orders']+') ',  Number(orders[4]['amount'])],
      [orders[5]['date']+' ('+orders[5]['orders']+') ',  Number(orders[5]['amount'])],
      [orders[6]['date']+' ('+orders[6]['orders']+') ',  Number(orders[6]['amount'])],
    ]}
    options={{
      // Material design options
      chart: {
        title: 'Weekly sales',
        subtitle: 'No of orders in each date of the week and total sales amount',
      },
      bars: 'horizontal'
    }}
    // For tests
    rootProps={{ 'data-testid': '2' }}
  />
}

export default BarChart;

