import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from '@material-ui/core/IconButton';

import { Formik } from "formik";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";

import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  imageInput: {
    display: "none",
  },
  redText: {
    color: red[800],
  },

  button: {
    display: 'inline'
  }
}));

export default function VariantTypeDeleteForm({ productId, variantTypeId, variantNo, removeVariantType }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
   
  };

  return (
    <React.Fragment>
      

      <IconButton aria-label="delete" color="secondary" className={classes.button} onClick={handleClickOpen}>
          <DeleteIcon fontSize="small" />
        </IconButton>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setLoading(true);

          axios
            .delete(`products/${productId}/variant-types/${variantTypeId}`)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                removeVariantType(variantTypeId, variantNo);
              }
            })
            .catch(function (error) {
              console.log(error);
              console.log(error.response);
              setLoading(false);
              // actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Delete Variant Type</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>
                Are you sure to delete the following variant type?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="secondary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <DeleteIcon />
                }
              >
                {isSubmitting ? "Deleting ..." : "Delete"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
