import React from 'react';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import PackageSizes from '../../components/shipping/packages/package-sizes.component';

const PackageSizesPage = () => {
    return (
          <PackageSizes />
    );
  } 

export default PackageSizesPage;
