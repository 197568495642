import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Avatar from "@material-ui/core/Avatar";

import Paper from "@material-ui/core/Paper";

import { useSnackbar } from "notistack";

import ChipInput from "material-ui-chip-input";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  imageInput: {
    display: "none",
  },
  redText: {
    color: red[800],
  },

  button: {
    display: "inline",
  },
}));

export default function VariantDeleteForm({ variant, removeVariant }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  console.log(variant);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {};

  return (
    <React.Fragment>
      <IconButton
        color="secondary"
        aria-label="delete"
        className={classes.button}
        onClick={handleClickOpen}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .delete(`products/${variant.product_id}/variants/${variant.id}`)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                removeVariant(variant.id);
              }
            })
            .catch(function (error) {
              console.log(error.response);
              setLoading(false);
              // actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Delete Variant</DialogTitle>

            <DialogContent dividers>
              <DialogContentText>
                Are you sure to delete the variant?
              </DialogContentText>

              <List>
                <ListItem>
                  <ListItemText primary={`SKU: ${variant.sku}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Price: £${variant.price}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Weight: ${variant.weight}`} />
                </ListItem>

                {variant.dimensions !== null ? (
                  <ListItem>
                    <ListItemText
                      primary={`Dimensions: ${variant.dimensions}`}
                    />
                  </ListItem>
                ) : (
                  ""
                )}
                <ListItem>
                  <ListItemText
                    primary={`Shipping Cost: £${variant.shipping_cost}`}
                  />
                </ListItem>

                {variant.variant_1_name !== undefined && variant.variant_1_name !== null ? (
                  <ListItem>
                    <ListItemText
                      primary={`${variant.variant_1_name}: ${variant.variant_1_value}`}
                    />
                  </ListItem>
                ) : (
                  ""
                )}

                {variant.variant_2_name !== undefined && variant.variant_2_name !== null ? (
                  <ListItem>
                    <ListItemText
                      primary={`${variant.variant_2_name}: ${variant.variant_2_value}`}
                    />
                  </ListItem>
                ) : (
                  ""
                )}

                {variant.variant_3_name !== undefined && variant.variant_3_name !== null ? (
                  <ListItem>
                    <ListItemText
                      primary={`${variant.variant_3_name}: ${variant.variant_3_value}`}
                    />
                  </ListItem>
                ) : (
                  ""
                )}

                <ListItem>
                  <ListItemText
                    primary={`Available: ${variant.available ? "Yes" : "No"}`}
                  />
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="secondary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <DeleteIcon />
                }
              >
                {isSubmitting ? "Deleting ..." : "Delete"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
