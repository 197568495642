import React from 'react';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import CollectionPoints from '../../components/shipping/collections/collection-points.component';

const CollectionPointsPage = () => {
    return (
          <CollectionPoints />
    );
  } 

export default CollectionPointsPage;
