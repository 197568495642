import React from "react";
import { useHistory } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import PublicIcon from "@material-ui/icons/Public";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import RoomIcon from '@material-ui/icons/Room';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      //  width: theme.spacing(24),
      height: theme.spacing(16),
    },
  },
  boxButton: {
    padding: theme.spacing(2),
    transition: "0.5s all ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer",
      color: theme.palette.info.main,
      boxShadow: `0 8px 6px -6px ${theme.palette.info.light}`,
    },
  },
  boxIcon: {
    display: "block",
    fontSize: 56,
    margin: "4px auto",
  },
  boxTitle: {
    textAlign: "center",
    fontSize: 16,
    margin: "8px auto",
  },
}));

export default function SimplePaper() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Paper
        elevation={1}
        className={classes.boxButton}
        onClick={() => history.push("/settings/shipping-options")}
      >
        <LocalShippingIcon className={classes.boxIcon} />
        <Typography component="h6" className={classes.boxTitle}>
          Shipping Options
        </Typography>
      </Paper>

      <Paper
        elevation={1}
        className={classes.boxButton}
        onClick={() => history.push("/settings/shipping-zones")}
      >
        <PublicIcon className={classes.boxIcon} />
        <Typography component="h6" className={classes.boxTitle}>
          Shipping Zones
        </Typography>
      </Paper>

      <Paper
        elevation={1}
        className={classes.boxButton}
        onClick={() => history.push("/settings/shipping-packages")}
      >
        <ShopTwoIcon className={classes.boxIcon} />
        <Typography component="h6" className={classes.boxTitle}>
          Package Sizes
        </Typography>
      </Paper>

      <Paper
        elevation={1}
        className={classes.boxButton}
        onClick={() => history.push("/settings/collection-points")}
      >
        <RoomIcon className={classes.boxIcon} />
        <Typography component="h6" className={classes.boxTitle}>
          Collection Points
        </Typography>
      </Paper>
    </div>
  );
}
