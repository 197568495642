import React, { useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";

import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, blue } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import { fade } from "@material-ui/core/styles/colorManipulator";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";

import EditIcon from "@material-ui/icons/Edit";

import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import ProductShippingUpdateForm from './product-shipping-update-form.component';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";

import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    maxWidth: "600px",
  },
  shippingOptionChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


const ProductShipping = ({ productId, productTitle }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [loadProductShipping, setLoadProductShipping] = React.useState(true);
  const [productShippingZones, setProductShippingZones] = React.useState([]);
  const [productShippingOptions, setProductShippingOptions] = React.useState([]);

  React.useEffect(() => {
    if(loadProductShipping) {
      setLoading(true);
      axios
        .get(`products/${productId}/shipping`)
        .then((response) => {
          console.log(response);
  
          if(response.data.status === 'success') {
            setProductShippingZones(response.data.data.zones);
            setProductShippingOptions(response.data.data.options);
            setLoadProductShipping(false);
          }
          
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    
  }, [loadProductShipping]);



  return loading ? '' : (
    <Card className={classes.card}>
      <CardHeader title={`${productTitle} shipping`} action={
            <ProductShippingUpdateForm 
            productId={productId}
            productTitle={productTitle}
            productShippingZones={productShippingZones}
            productShippingOptions={productShippingOptions}
            setLoadProductShipping={setLoadProductShipping}
             />
          }/>
      <Divider />
      <CardContent>
      <Typography variant="h6" color="textSecondary" gutterBottom>Shipping zones</Typography>
      {productShippingZones.map(item =>
        <Chip key={item.id} label={item.title} variant="outlined" className={classes.shippingOptionChip}/>
      )}
      <Typography variant="h6" color="textSecondary" gutterBottom>Shipping options</Typography>
      {productShippingOptions.map(item =>
        <Chip key={item.id} label={item.provider +' - '+ item.service +' ('+ item.speed+')'} variant="outlined" className={classes.shippingOptionChip}/>
      )}
       
      </CardContent>
    </Card>
  );
};

export default ProductShipping;
