import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
  },
}));

const SkeletonCard = ({ message }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <CircularProgress />
          }
          title={<Typography variant="h5" color="textSecondary">{`${message === undefined ? 'Please wait ...' : message}`}</Typography>}
        />
        <Divider />
        <CardContent dividers="true">
          <Skeleton animation="wave" style={{ marginBottom: 8 }} />
          <Skeleton animation="wave" style={{ marginBottom: 8 }} />
          <Skeleton animation="wave" style={{ marginBottom: 8 }} />
          <Skeleton animation="wave" style={{ marginBottom: 8 }} />
          <Skeleton animation="wave" style={{ marginBottom: 8 }} />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default SkeletonCard;
