import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries } from "../../../config/countries";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import Typography from "@material-ui/core/Typography";

import ImportExportIcon from "@material-ui/icons/ImportExport";

import PublicIcon from "@material-ui/icons/Public";
import { useSnackbar } from "notistack";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  addCountry: {
    padding: "8px",
    marginTop: '-8px',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.info.light,
    }
  },
}));

const ShippingCountriesSchema = Yup.object().shape({
  shipping_countries: Yup.array(),
});

export default function ShippingCountriesForm({ shippingZone, updateShippingZone }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadShippingCountries, setLoadShippingCountries] = React.useState(
    true
  );
  const [shippingCountries, setShippingCountries] = React.useState([]);

  React.useEffect(() => {
    console.log("loading shipping countries");
    if (loadShippingCountries) {
      axios
        .get(`shipping-zones/${shippingZone.id}/countries`)
        .then((response) => {

          const shippingZoneCountries = response.data.data;

          const filteredCountries = countries.filter(
            (ar) => shippingZoneCountries.find((rm) => rm.country_code === ar.code)
          );


          setShippingCountries(filteredCountries);


          setLoadShippingCountries(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadShippingCountries(false);
        });
    }
  }, [loadShippingCountries]);

  const handleClickOpen = (setFieldValue) => {
    
    console.log(setFieldValue);
    setFieldValue('shipping_countries', shippingCountries);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    shipping_countries: shippingCountries,
  };

  return (
      <Formik
        initialValues={initialValues}
        validationSchema={ShippingCountriesSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .put(`shipping-zones/${shippingZone.id}/countries`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              //  actions.resetForm(true);
              setOpen(false);
              setLoadShippingCountries(true);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                updateShippingZone();
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
              // actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <React.Fragment>
          {shippingCountries.length > 0 ? (
            shippingCountries.map((shippingCountry) => (
              <Chip
              key={shippingCountry.code}
                variant="outlined"
                avatar={<Avatar style={{backgroundColor: 'white'}}>{countryToFlag(shippingCountry.code)}</Avatar>}
                label={countries.find(item => item.code === shippingCountry.code).label}
                style={{ marginRight: 8, marginBottom: 8 }}
              />
            ))
          ) : (
            <Typography variant="subtitle2" gutterBottom style={{marginBottom: 12}}>
              No country added for the shipping zone.
            </Typography>
          )}

          <Chip
                avatar={<Avatar style={{backgroundColor: 'white'}}><ImportExportIcon /></Avatar>}
                label="Add or Remove Country"
                className={classes.addCountry}
                onClick={() => handleClickOpen(setFieldValue)}
              />

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {`${shippingZone.title}`} shipping zone countries
            </DialogTitle>

            <DialogContent dividers={true}>
              <FormControl fullWidth className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="shipping_countries"
                  name="shipping_countries"
                  style={{ minWidth: 400 }}
                  options={countries}
                  defaultValue={shippingCountries.map(item => item)}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option, value) =>
                    option.code === value.code
                  }
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{countryToFlag(option.code)}</span>
                      {option.label}
                    </React.Fragment>
                  )}
                  onChange={(e, value) => {
                    setFieldValue("shipping_countries", value);
                  }}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Shipping countries"
                      helperText={errors ? errors.shipping_countries : ""}
                      onBlur={handleBlur}
                      error={
                        errors &&
                        errors.shipping_countries &&
                        touched.shipping_countries
                          ? true
                          : null
                      }
                    />
                  )}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <SaveIcon />
                }
              >
                {isSubmitting ? "Saving ..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
          </React.Fragment>
        )}
      </Formik>
   
  );
}
