import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import EditIcon from "@material-ui/icons/Edit";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
  redText: {
    color: red[800],
  },
}));

const PackageSizeSchema = Yup.object().shape({
  format: Yup.string().required("Format is required."),
  length: Yup.number()
    .required("Length from is required.")
    .positive()
    .typeError("Length from must be a positive number."),
  width: Yup.number()
    .required("Width from is required.")
    .positive()
    .typeError("Width from must be a positive number."),
  height: Yup.number()
    .required("Height from is required.")
    .positive()
    .typeError("Height from must be a positive number."),
  min_weight: Yup.number()
    .required("Minimum weight from is required.")
    .typeError("Minimum weight from must be a number."),
  max_weight: Yup.number()
    .required("Maximum weight from is required.")
    .positive()
    .typeError("Maximum weight from must be a positive number."),
});

export default function PackageSizeEditForm({ packageSize, updatePackageSize }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    format: packageSize.format,
    length: packageSize.length,
    width: packageSize.width,
    height: packageSize.height,
    min_weight: packageSize.min_weight,
    max_weight: packageSize.max_weight,
    available: packageSize.available,
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="edit"
        style={{ margin: 0 }}
        onClick={handleClickOpen}
      >
        <EditIcon fontSize="small"/>
      </IconButton>

      <Formik
        initialValues={initialValues}
        validationSchema={PackageSizeSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .put(`shipping-package-sizes/${packageSize.id}`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
            //  actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                updatePackageSize(response.data.data);

              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
             // actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Edit Shipping Package Size
            </DialogTitle>

            <DialogContent dividers={true}>
              
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="format"
                  label="Format"
                  type="text"
                  value={values.format}
                  helperText={errors ? errors.format : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.format && touched.format ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="length"
                  label="Length (cm)"
                  type="text"
                  value={values.length}
                  helperText={errors ? errors.length : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.length && touched.length ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="width"
                  label="Width (cm)"
                  type="text"
                  value={values.width}
                  helperText={errors ? errors.width : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.width && touched.width ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="height"
                  label="Height (cm)"
                  type="text"
                  value={values.height}
                  helperText={errors ? errors.height : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.height && touched.height ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="min_weight"
                  label="Min Weight (g)"
                  type="text"
                  value={values.min_weight}
                  helperText={errors ? errors.min_weight : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.min_weight && touched.min_weight
                      ? true
                      : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="max_weight"
                  label="Max Weight (g)"
                  type="text"
                  value={values.max_weight}
                  helperText={errors ? errors.max_weight : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.max_weight && touched.max_weight
                      ? true
                      : null
                  }
                />
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.available === 1 ? true : false}
                    onChange={(e, value) => {
                      setFieldValue("available", value ? 1 : 0);
                    }}
                    name="available"
                    color="primary"
                  />
                }
                label="Available"
              />
            </DialogContent>
            
            
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <SaveIcon />
                }
              >
                {isSubmitting ? "Saving ..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
