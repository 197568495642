import React from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import BarChart from "../../components/dashboard/bar-chart.component";
import PieChart from "../../components/dashboard/pie-chart.component";
import LineChart from "../../components/dashboard/line-chart.component";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Dashboard = () => {
  const classes = useStyles();

  const [load, setLoad] = React.useState(true);
  const [stats, setStats] = React.useState(undefined);
 
  const history = useHistory();

  React.useEffect(() => {
    console.log("run");
    //setLoading(true);

    if(load) {
      axios
      .get(`orders/statistics`)
      .then((response) => {
        setStats(response.data.data);
        setLoad(false);
        // console.log(response.data.data);

        // console.log(response.data.data);


      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
      });
    }

    
  }, [load]);

  return stats !== undefined ? (
    <div className={classes.root}>
      <Grid container spacing={3}>
      <Grid item xs={12}>
          
          <BarChart orders={stats.order_days} /> 
        </Grid>
        <Grid item xs={12}>
          
        <PieChart products={stats.order_items} />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <PieChart /> */}
        </Grid>
        <Grid item xs={12} md={6}>
        {/* <LineChart /> */}
        </Grid>
      </Grid>
    </div>
  ) : 'Loading ...';
};

export default Dashboard;
