import React, { Component } from "react";
import axios from "axios";
import { UserListNav } from "../../components/users/user-nav.component";
import UserList from "../../components/users/user-list.component";
import CircularProgress from "../../components/common/circular-progress.component";

class UserListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
    };
  }

  componentDidMount = () => {
   // console.log(localStorage.getItem('access_token'));
    axios
      .get(`/auth/users`)
      .then((response) => {
        this.setState({
          users: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (Object.keys(this.state.users).length > 0) {
      return (
        <div>
          <UserListNav />
          <UserList data={this.state.users} />{" "}
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default UserListPage;
