import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import OrderDeliveryItems from "./order-delivery-items.component";

const useStyles = makeStyles({
  root: {
    marginTop: 16,
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  marginTop: {
    marginTop: 12,
  },
});

export default function OrderDeliveryDetails({ collection }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div>
          Collection Type: <strong>Collection ({collection.speed})</strong>
        </div>
        <div>
          Cost: <strong>£{collection.cost}</strong>
        </div>

        <div className={classes.marginTop}>Collection items:</div>
        <OrderDeliveryItems items={collection.items} />

        <div className={classes.marginTop}>Collection address:</div>
        {collection.address.name !== "" ? (
          <div>{collection.address.name}</div>
        ) : (
          ""
        )}
        {collection.address.address_line_1 !== "" ? (
          <div>{collection.address.address_line_1}</div>
        ) : (
          ""
        )}
        {collection.address.address_line_2 !== "" ? (
          <div>{collection.address.address_line_2}</div>
        ) : (
          ""
        )}
        {collection.address.city !== "" ? (
          <div>{collection.address.city}</div>
        ) : (
          ""
        )}
        {collection.address.county !== "" ? (
          <div>{collection.address.county}</div>
        ) : (
          ""
        )}
        {collection.address.postcode !== "" ? (
          <div>{collection.address.postcode}</div>
        ) : (
          ""
        )}
        {collection.address.country_code !== "" ? (
          <div>{collection.address.country_code}</div>
        ) : (
          ""
        )}
      </CardContent>
    </Card>
  );
}
