import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Avatar from "@material-ui/core/Avatar";

import Paper from "@material-ui/core/Paper";

import { useSnackbar } from "notistack";

import ChipInput from "material-ui-chip-input";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  imageInput: {
    display: "none",
  },
  redText: {
    color: red[800],
  },

  button: {
    display: "inline",
    marginLeft: "12px",
  },
}));

export default function PackageSizeDeleteForm({ packageSize, removePackageSize }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {};

  return (
    <React.Fragment>
      <IconButton
        size="small"
        color="secondary"
        aria-label="delete"
        className={classes.button}
        onClick={handleClickOpen}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .delete(`shipping-package-sizes/${packageSize.id}`)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                removePackageSize(packageSize.id);
              }
            })
            .catch(function (error) {
              console.log(error.response);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Delete Shipping Package Size</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>
                Are you sure to delete the {`${packageSize.format}`} shipping package size?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="secondary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <DeleteIcon />
                }
              >
                {isSubmitting ? "Deleting ..." : "Delete"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
