import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import EditIcon from "@material-ui/icons/Edit";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
  redText: {
    color: red[800],
  },
}));

const ShippingOptionSchema = Yup.object().shape({
  provider: Yup.string().required("Provider is required."),
  service: Yup.string().required("Service is required."),
  speed: Yup.string().required("Speed is required."),
  tracking_type: Yup.string().when("has_tracking", {
    is: (value) => value && value === 1,
    then: Yup.string()
      .required("Tracking type is required.")
      .typeError("Tracking type is required."),
    otherwise: Yup.string(),
  }),
  min_weight: Yup.number().typeError("Minimum weight from must be a number."),
  max_weight: Yup.number()
    .positive()
    .typeError("Maximum weight from must be a positive number."),
});

export default function ShippingOptionEditForm({
  shippingOption,
  updateShippingOption,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hasTracking, setHasTracking] = React.useState(
    shippingOption.has_tracking
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    provider: shippingOption.provider,
    service: shippingOption.service,
    speed: shippingOption.speed,
    has_tracking: shippingOption.has_tracking,
    tracking_type: shippingOption.tracking_type,
    min_weight: shippingOption.min_weight,
    max_weight: shippingOption.max_weight,
    available: shippingOption.available,
    note: shippingOption.note,
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="edit"
        style={{ margin: 0 }}
        onClick={handleClickOpen}
      >
        <EditIcon fontSize="small" />
      </IconButton>

      <Formik
        initialValues={initialValues}
        validationSchema={ShippingOptionSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .put(`shipping-options/${shippingOption.id}`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              //  actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                updateShippingOption(response.data.data);
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
              // actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Edit Shipping Option
            </DialogTitle>

            <DialogContent dividers={true}>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="provider"
                  label="Provider"
                  type="text"
                  value={values.provider}
                  helperText={errors ? errors.provider : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.provider && touched.provider ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="service"
                  label="Service"
                  type="text"
                  value={values.service}
                  helperText={errors ? errors.service : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.service && touched.service ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="speed"
                  label="Speed"
                  type="text"
                  value={values.speed}
                  helperText={errors ? errors.speed : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.speed && touched.speed ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.has_tracking === 1 ? true : false}
                      onChange={(e, value) => {
                        setFieldValue("has_tracking", value ? 1 : 0);
                        setHasTracking(value ? 1 : 0);
                      }}
                      name="has_tracking"
                      color="primary"
                    />
                  }
                  label="Has tracking"
                />
              </FormControl>

              {hasTracking === 1 ? (
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    name="tracking_type"
                    label="Tracking Type"
                    type="text"
                    value={values.tracking_type}
                    helperText={errors ? errors.tracking_type : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors && errors.tracking_type && touched.tracking_type
                        ? true
                        : null
                    }
                  />
                </FormControl>
              ) : (
                ""
              )}

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="min_weight"
                  label="Min Weight (g)"
                  type="text"
                  value={values.min_weight}
                  helperText={errors ? errors.min_weight : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.min_weight && touched.min_weight
                      ? true
                      : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="max_weight"
                  label="Max Weight (g)"
                  type="text"
                  value={values.max_weight}
                  helperText={errors ? errors.max_weight : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.max_weight && touched.max_weight
                      ? true
                      : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.available === 1 ? true : false}
                      onChange={(e, value) => {
                        setFieldValue("available", value ? 1 : 0);
                      }}
                      name="available"
                      color="primary"
                    />
                  }
                  label="Available"
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  multiline
                  rowsMax={4}
                  name="note"
                  label="Note"
                  type="text"
                  value={values.note}
                  helperText={errors ? errors.note : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.note && touched.note ? true : null}
                />
              </FormControl>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <SaveIcon />
                }
              >
                {isSubmitting ? "Saving ..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
