import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
}));

const ShippingRateSchema = Yup.object().shape({
  cost: Yup.number().required("Cost is required."),
  speed: Yup.string().required("Speed is required."),
  });

export default function CollectionRateAddForm({
  collectionPointId,
  addCollectionRate,
  fullButton,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [costBasedOnOption, setCostBasedOption] = React.useState("");
  const [coverParcelDamage, setCoverParcelDamage] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    cost: "",
    speed: "",
    available: 0,
    note: "",
  };

  return (
    <React.Fragment>
      {fullButton === true ? (
        <React.Fragment>
          <Typography variant="subtitle2" gutterBottom>
            No shipping rates found for the shipping zone.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Add shipping rate
          </Button>
        </React.Fragment>
      ) : (
        <IconButton
          size="small"
          aria-label="add"
          style={{ margin: 0 }}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </IconButton>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={ShippingRateSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .post(`collection-points/${collectionPointId}/rates`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                addCollectionRate(response.data.data);
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Add New Shipping Rate
            </DialogTitle>

            <DialogContent dividers="true">

            <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="speed"
                  label="Speed"
                  type="text"
                  value={values.speed}
                  helperText={errors ? errors.speed : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.speed && touched.speed ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="cost"
                  label="Cost (£)"
                  type="text"
                  value={values.cost}
                  helperText={errors ? errors.cost : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.cost && touched.cost ? true : null}
                />
              </FormControl>
              
              <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.available === 1 ? true : false}
                      onChange={(e, value) => {
                        setFieldValue("available", value ? 1 : 0);
                      }}
                      name="available"
                      color="primary"
                    />
                  }
                  label="Available"
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="note"
                  label="Note"
                  type="text"
                  value={values.note}
                  helperText={errors ? errors.note : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.note && touched.note ? true : null}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <SaveIcon />
                }
              >
                {isSubmitting ? "Saving ..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
