import React from "react";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

import ShippingRateAddForm from "./shipping-rate-add-form.component";
import ShippingRateEditForm from "./shipping-rate-edit-form.component";
import ShippingRateDeleteForm from "./shipping-rate-delete-form.component";
import SkeletonCard from "../../../common/skeleton.component";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1200,
  },
  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableSubHead: {
    backgroundColor: grey[300],
    color: theme.palette.primary.dark,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid ${grey[300]}`,
  },
}));

const costBasedOnOptions = [
  { code: "basket_weight", label: "Basket Weight" },
  { code: "basket_value", label: "Basket Value" },
];

export default function ShippingRates({
  zoneId,
  availableShippingPackages,
  availableShippingOptions,
}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [shippingRates, setShippingRates] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`shipping-zones/${zoneId}/rates`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          setShippingRates(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const addShippingRate = (rate) => {
    shippingRates.push(rate);
    setShippingRates(shippingRates.map((item) => item));
  };

  const updateShippingRate = (rate) => {
    setShippingRates(
      shippingRates.map((item) => (item.id !== rate.id ? item : rate))
    );
  };

  const removeShippingRate = (rateId) => {
    setShippingRates(shippingRates.filter((item) => item.id !== rateId));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return shippingRates.length > 0 ? (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align={"center"} className={classes.tableSubHead}>
                Package
              </TableCell>

              <TableCell align={"center"} className={classes.tableSubHead}>
                Delivery
              </TableCell>
              <TableCell align={"center"} className={classes.tableSubHead}>
                Cost Based On
              </TableCell>

              <TableCell align={"center"} className={classes.tableSubHead}>
                Weight (g)
              </TableCell>

              <TableCell align={"center"} className={classes.tableSubHead}>
                Value (£)
              </TableCell>

              <TableCell
                align={"center"}
                className={classes.tableSubHead}
                align={"center"}
              >
                Cost (£)
              </TableCell>

              <TableCell align={"center"} className={classes.tableSubHead}>
                Available
              </TableCell>
              <TableCell className={classes.tableSubHead} align={"center"}>
                <ShippingRateAddForm
                  zoneId={zoneId}
                  addShippingRate={addShippingRate}
                  availableShippingPackages={availableShippingPackages}
                  availableShippingOptions={availableShippingOptions}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shippingRates.map((rate, index) => (
              <TableRow hover tabIndex={-1} key={rate.id}>
                <TableCell align={"center"} className={classes.columnBorder}>
                  {rate.note === null || rate.note === "" ? (
                    <Chip variant="outlined" label={rate.package} />
                  ) : (
                    <Tooltip title={rate.note} placement="top">
                      <Chip label={rate.package} />
                    </Tooltip>
                  )}
                </TableCell>

                <TableCell align={"center"} className={classes.columnBorder}>
                  {rate.shipping_provider} - {rate.shipping_service} <br />(
                  {rate.shipping_speed})
                </TableCell>

                <TableCell align={"center"} className={classes.columnBorder}>
                  {
                    costBasedOnOptions.find(
                      (item) => item.code === rate.cost_based_on
                    ).label
                  }
                </TableCell>

                <TableCell align={"center"} className={classes.columnBorder}>
                  {rate.cost_based_on === "basket_weight" ? (
                    <React.Fragment>
                      {rate.min_weight}
                      <ArrowRightAltIcon />
                      {rate.max_weight}
                    </React.Fragment>
                  ) : (
                    <CloseIcon fontSize="small" color="secondary" />
                  )}
                </TableCell>

                <TableCell align={"center"} className={classes.columnBorder}>
                  {rate.cost_based_on === "basket_value" ? (
                    <React.Fragment>
                      {rate.min_value}
                      <ArrowRightAltIcon />
                      {rate.max_value}
                    </React.Fragment>
                  ) : (
                    <CloseIcon fontSize="small" color="secondary" />
                  )}
                </TableCell>

                <TableCell align={"center"} className={classes.columnBorder}>
                  {rate.cost}
                </TableCell>

                <TableCell align={"center"} className={classes.columnBorder}>
                  {rate.available === 1 ? (
                    <CheckIcon color="primary" fontSize="small" />
                  ) : (
                    <CloseIcon color="secondary" fontSize="small" />
                  )}
                </TableCell>
                <TableCell align={"center"} width="140">
                  <React.Fragment>
                    <ShippingRateEditForm
                          rate={rate}
                          updateShippingRate={updateShippingRate}
                          availableShippingPackages={availableShippingPackages}
                          availableShippingOptions={availableShippingOptions}
                          key={index}
                        />
                    <ShippingRateDeleteForm
                          rate={rate}
                          removeShippingRate={removeShippingRate}
                        />
                  </React.Fragment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  ) : (
    <ShippingRateAddForm
      zoneId={zoneId}
      addShippingRate={addShippingRate}
      availableShippingPackages={availableShippingPackages}
      availableShippingOptions={availableShippingOptions}
      fullButton={true}
    />
  );
}
