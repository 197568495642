import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from "@material-ui/icons/Save";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  chipButton: {
    padding: "8px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.info.light,
    },
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 300,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const ShippingProductsSchema = Yup.object().shape({
  products: Yup.array(),
});

export default function ShippingZoneProducts({ shippingZone }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadShippingProducts, setLoadShippingProducts] = React.useState(true);
  const [loadAvailableProducts, setLoadAvailableProducts] = React.useState(true);

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  React.useEffect(() => {
    console.log("loading shipping products " + shippingZone.id);
    if (loadShippingProducts) {
      axios
        .get(`shipping-zones/${shippingZone.id}/products`)
        .then((response) => {
          setLoadShippingProducts(false);
          setRight(response.data.data);

          return response.data.data;
        })
        .then((shippingProducts) => {
          console.log("loading available products");
          if (loadAvailableProducts) {
            axios
              .get(`available-products`)
              .then((response) => {
                const filteredProducts = response.data.data.filter(
                  (ar) => !shippingProducts.find((rm) => rm.id === ar.id)
                );
                setLeft(filteredProducts);
                setLoadAvailableProducts(false);
              })
              .catch((error) => {
                console.log(error);
                setLoadAvailableProducts(false);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadShippingProducts(false);
        });
    }
  }, [loadShippingProducts, loadAvailableProducts]);


  const handleClickOpen = (setFieldValue) => {
    setFieldValue(
      "products",
      right.map((item) => item.id)
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    products: right.map((item) => item.id),
  };

  const productList = (title, items) => (
    <Card style={{ width: 300 }}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((item) => {
          const labelId = `transfer-list-all-item-${item.id}-label`;

          return (
            <ListItem
              key={item.id}
              role="listitem"
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${item.title}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ShippingProductsSchema}
      onSubmit={(values, actions) => {
        setLoading(true);

        // console.log(right);

        // const products = right.map(item => item.id);

        //  console.log(products);
        //   actions.setFieldValue('products', products);

        values.products = right.map((item) => item.id);

        console.log(values);

        axios
          .put(`shipping-zones/${shippingZone.id}/products`, values)
          .then((response) => {
            console.log(response);
            setLoading(false);
            actions.setSubmitting(false);
            //  actions.resetForm(true);
            setOpen(false);
            setLoadShippingProducts(true);

            if (response.data && response.data.status) {
              enqueueSnackbar(`${response.data.message}`, {
                variant: `${response.data.status}`,
              });
            }

            if (response.data && response.data.status === "success") {
              // updateShippingZone(response.data.data);
            }
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false);
            // actions.setErrors(error.response.data.errors);
            actions.setSubmitting(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <React.Fragment>
          <Chip
            avatar={
              <Avatar style={{ backgroundColor: "white" }}>
                {right.length}
              </Avatar>
            }
            label="Products"
            className={classes.chipButton}
            onClick={() => handleClickOpen(setFieldValue)}
          />

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle id="form-dialog-title">
              Product available to ship to {shippingZone.title}
            </DialogTitle>

            <DialogContent dividers={true}>
              <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                className={classes.root}
              >
                <Grid item>{productList("Available products", left)}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>{productList("Selected products", right)}</Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <SaveIcon />
                }
              >
                {isSubmitting ? "Saving ..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    </Formik>
  );
}
