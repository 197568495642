import React, { useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { Editor } from "@tinymce/tinymce-react";
import { tinymce } from '../../config/tinymce';

import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, blue } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import { fade } from "@material-ui/core/styles/colorManipulator";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";

import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    maxWidth: '600px'
  },
  formName: {
    margin: theme.spacing(1),
    width: "100%",
    display: "block",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  bapro: {
    width: "200px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: fade(blue[100], 0.5),
    color: blue[800],
  },

  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CategorySchema = Yup.object().shape({
  title: Yup.string().required("Title is required."),
  description: Yup.string().required("Description is required"),
});

const CategoryCreateForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const initialValues = {
    title: "",
    standfirst: "",
    description: "",
  };

  return (
    <Card className={classes.card}>
      <Formik
        initialValues={initialValues}
        validationSchema={CategorySchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          axios
            .post(`categories`, values)
            .then((response) => {

              setLoading(false);
              actions.setSubmitting(false);

              if(response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if(response.data && response.data.status === 'success') {
                history.push(`/categories/${response.data.data.id}/details`);
              }
            })
            .catch(function (error) {
              console.log(error.response);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <CardHeader subheader="Create a new product category here" title="Category" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="title"
                      label="Title"
                      value={values.title}
                      helperText={errors ? errors.title : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.title && touched.title ? true : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="standfirst"
                      label="Standfirst"
                      value={values.standfirst}
                      helperText={errors ? errors.standfirst : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.standfirst && touched.standfirst
                          ? true
                          : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <Editor
                      apiKey="1kpkwo9hnwqzfgbll0i17r140exrbx0liebwvsfyv8xuwvgk"
                      name="description"
                      value={values.description}
                      init={tinymce}
                      helperText={errors ? errors.description : ""}
                      onEditorChange={(e) => {
                        handleChange({
                          target: { name: "description", value: e },
                        });
                      }}
                      onBlur={handleBlur}
                      error={
                        errors && errors.description && touched.description
                          ? true
                          : null
                      }
                    />
                  </FormControl>
                </Grid>


              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={handleSubmit}
                  //  className={classes.submitButton}
                  startIcon={<SaveIcon />}
                >
                  {isSubmitting ? "Saving ..." : "Save"}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default CategoryCreateForm;
