import React from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import Icon from '@material-ui/core/Icon';

import PublicIcon from "@material-ui/icons/Public";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";


import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.info.light,
    }
  }
}));

export const ShippingPackagesNav = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<PublicIcon />}
          onClick={() => history.push('/settings/shipping-zones')}
        >
          Shipping Zones
        </Button>

        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<LocalShippingIcon />}
          onClick={() => history.push('/settings/shipping-options')}
        >
          Shipping Options
        </Button>
</div>
  );
}

export const ShippingOptionsNav = () => {
    const classes = useStyles();
    const history = useHistory();
  
    return (
      <div>
       <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<PublicIcon />}
          onClick={() => history.push('/settings/shipping-zones')}
        >
          Shipping Zones
        </Button>

        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<ShopTwoIcon />}
          onClick={() => history.push('/settings/shipping-packages')}
        >
          Shipping Packages
        </Button>
      </div>
    );
  }

export const ShippingZonesNav = () => {
    const classes = useStyles();
    const history = useHistory();
  
    return (
      <div>
        

        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<LocalShippingIcon />}
          onClick={() => history.push('/settings/shipping-options')}
        >
          Shipping Options
        </Button>

        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<ShopTwoIcon />}
          onClick={() => history.push('/settings/shipping-packages')}
        >
          Shipping Packages
        </Button>
      </div>
    );
  }
  
  
