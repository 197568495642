import React from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import Icon from '@material-ui/core/Icon';


import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.info.light,
    }
  }
}));

export const ProductListNav = () => {
  const classes = useStyles();
  const history = useHistory();

  

  return (
    <div>


  <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={() => history.push('/products/create')}
        >
          Create New Product
        </Button>


      


</div>
  );
}

export const CreateProductNav = () => {
    const classes = useStyles();
    const history = useHistory();
  
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<ListIcon />}
          onClick={() => history.push('/products')}
        >
         List of Products
        </Button>
      </div>
    );
  }

export const ProductNav = () => {
    const classes = useStyles();
    const history = useHistory();
  
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={() => history.push('/products/create')}
        >
          Create New Product
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<ListIcon />}
          onClick={() => history.push('/products')}
        >
         List of Products
        </Button>
      </div>
    );
  }
  
  
