import React, { Component } from "react";
import axios from "axios";
import { CreateProductNav } from "../../components/products/product-nav.component";
import ProductCreateForm from "../../components/products/product-create-form.component";
import CircularProgress from "../../components/common/circular-progress.component";

class CreateProductPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: {},
    };
  }

  componentDidMount = () => {
    axios
      .get(`categories`)
      .then((response) => {
        let categories = response.data.data.map((item) => {
          return {
            id: item.id,
            title: item.title,
          };
        });

        this.setState({
          categories: categories,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (Object.keys(this.state.categories).length > 0) {
      return (
        <div>
          <CreateProductNav />
          <ProductCreateForm categories={this.state.categories} />
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default CreateProductPage;
