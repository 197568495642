import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import AddIcon from "@material-ui/icons/Add";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  redText: {
    color: red[800],
  },
}));

const ShippingZoneSchema = Yup.object().shape({
  title: Yup.string().required("Title is required."),
});

export default function ShippingZoneAddForm({ addShippingZone }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    title: "",
    available: 0,
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        aria-label="add"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Create New Shipping Zone
      </Button>

      <Formik
        initialValues={initialValues}
        validationSchema={ShippingZoneSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .post(`shipping-zones`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
              //  addShippingZone(response.data.data);
                history.push(`/settings/shipping-zones/${response.data.data.id}/details`);
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Add New Shipping Zone
            </DialogTitle>

            <DialogContent dividers="true">
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="title"
                  label="Title"
                  type="text"
                  value={values.title}
                  helperText={errors ? errors.title : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.title && touched.title ? true : null}
                />
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.available}
                    onChange={(e, value) => {
                      setFieldValue("available", value ? 1 : 0);
                    }}
                    name="available"
                    color="primary"
                  />
                }
                label="Available"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <SaveIcon />
                }
              >
                {isSubmitting ? "Saving ..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
