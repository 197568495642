import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Avatar from "@material-ui/core/Avatar";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

import Autocomplete from "@material-ui/lab/Autocomplete";

import Paper from "@material-ui/core/Paper";

import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
  redText: {
    color: red[800],
  },
}));

const VariantSchema = Yup.object().shape({
  price: Yup.number()
    .required("Price is required.")
    .positive()
    .typeError("Price must be a positive number."),
  weight: Yup.number()
    .required("Weight is required.")
    .positive()
    .typeError("Weight must be a positive number."),
  length: Yup.number()
    .required("Length is required.")
    .positive()
    .typeError("Length must be a positive number."),
  width: Yup.number()
    .required("Width is required.")
    .positive()
    .typeError("Width must be a positive number."),
  height: Yup.number()
    .required("Height is required.")
    .positive()
    .typeError("Height must be a positive number."),
  additional_shipping_cost: Yup.number()
    .typeError("Additional shipping cost must be a positive number."),
  stock: Yup.number()
    .required("Stock is required.")
    .typeError("Stock must be a number."),
});

export default function VariantAddForm({
  productId,
  variantTypes,
  addVariant,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [shippingNotRequired, setShippingNotRequired] = React.useState(false);

  console.log(variantTypes);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleChip = (chip) => {
    console.log(chip);
  };

  const handleDeleteChip = (chip, index) => {};

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    sku: "",
    price: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    shipping_not_required: 0,
    separated_shipping_required: 0,
    additional_shipping_cost: "",
    variant_1_id: variantTypes[1] !== undefined ? variantTypes[1].id : "",
    variant_1_value: "",
    variant_2_id: variantTypes[2] !== undefined ? variantTypes[2].id : "",
    variant_2_value: "",
    variant_3_id: variantTypes[3] !== undefined ? variantTypes[3].id : "",
    variant_3_value: "",
    stock: "",
  };

  console.log(initialValues);

  return (
    <div>
      <Fab size="small" aria-label="add" onClick={handleClickOpen}>
        <AddIcon />
      </Fab>

      <Formik
        initialValues={initialValues}
        validationSchema={VariantSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .post(`products/${productId}/variants`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                console.log("add");
                addVariant(response.data.data);
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
              // actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Add New Product Variant
            </DialogTitle>

            <DialogContent dividers={true}>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="sku"
                  label="SKU"
                  type="text"
                  value={values.sku}
                  helperText={errors ? errors.sku : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.sku && touched.sku ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="price"
                  label="Price"
                  type="text"
                  value={values.price}
                  helperText={errors ? errors.price : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.price && touched.price ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="weight"
                  label="Weight"
                  type="text"
                  value={values.weight}
                  helperText={errors ? errors.weight : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.weight && touched.weight ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="length"
                  label="Length"
                  type="text"
                  value={values.length}
                  helperText={errors ? errors.length : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.length && touched.length ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="width"
                  label="Width"
                  type="text"
                  value={values.width}
                  helperText={errors ? errors.width : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.width && touched.width ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="height"
                  label="Height"
                  type="text"
                  value={values.height}
                  helperText={errors ? errors.height : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.height && touched.height ? true : null
                  }
                />
              </FormControl>

              {/* <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.shipping_not_required}
                      onChange={(e, value) => {
                        setFieldValue("shipping_not_required", value ? 1 : 0);
                        setFieldValue("separated_shipping_required", 0);
                        setFieldValue("additinal_shipping_cost", 0);
                        setShippingNotRequired(value);
                      }}
                      name="shipping_not_required"
                      color="primary"
                    />
                  }
                  label="Shipping Not Required"
                />
              </FormControl> */}

              {shippingNotRequired === false ? (
                <React.Fragment>
                  <FormControl fullWidth className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.separated_shipping_required}
                          onChange={(e, value) => {
                            setFieldValue(
                              "separated_shipping_required",
                              value ? 1 : 0
                            );
                          }}
                          name="separated_shipping_required"
                          color="primary"
                        />
                      }
                      label="Separated Shipping Required"
                    />
                  </FormControl>

                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      name="additional_shipping_cost"
                      label="Additional Shipping Cost"
                      type="text"
                      value={values.additional_shipping_cost}
                      helperText={errors ? errors.additional_shipping_cost : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors &&
                        errors.additional_shipping_cost &&
                        touched.additional_shipping_cost
                          ? true
                          : null
                      }
                    />
                  </FormControl>
                </React.Fragment>
              ) : (
                ""
              )}

              {variantTypes[1] !== undefined ? (
                <FormControl fullWidth className={classes.formControl}>
                  <Autocomplete
                    options={variantTypes[1].options.split(",")}
                    onChange={(e, value) => {
                      setFieldValue("variant_1_value", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${variantTypes[1].name}`}
                        helperText={errors ? errors.variant_1_value : ""}
                        onBlur={handleBlur}
                        error={
                          errors &&
                          errors.variant_1_value &&
                          touched.variant_1_value
                            ? true
                            : null
                        }
                      />
                    )}
                  />
                </FormControl>
              ) : (
                ""
              )}

              {variantTypes[2] !== undefined ? (
                <FormControl fullWidth className={classes.formControl}>
                  <Autocomplete
                    options={variantTypes[2].options.split(",")}
                    onChange={(e, value) => {
                      setFieldValue("variant_2_value", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${variantTypes[2].name}`}
                        helperText={errors ? errors.variant_2_value : ""}
                        onBlur={handleBlur}
                        error={
                          errors &&
                          errors.variant_2_value &&
                          touched.variant_2_value
                            ? true
                            : null
                        }
                      />
                    )}
                  />
                </FormControl>
              ) : (
                ""
              )}

              {variantTypes[3] !== undefined ? (
                <FormControl fullWidth className={classes.formControl}>
                  <Autocomplete
                    options={variantTypes[3].options.split(",")}
                    onChange={(e, value) => {
                      setFieldValue("variant_3_value", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${variantTypes[3].name}`}
                        helperText={errors ? errors.variant_3_value : ""}
                        onBlur={handleBlur}
                        error={
                          errors &&
                          errors.variant_3_value &&
                          touched.variant_3_value
                            ? true
                            : null
                        }
                      />
                    )}
                  />
                </FormControl>
              ) : (
                ""
              )}

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="stock"
                  label="Stock"
                  type="text"
                  value={values.stock}
                  helperText={errors ? errors.stock : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.stock && touched.stock ? true : null}
                />
              </FormControl>
            </DialogContent>
            
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <SaveIcon />
                }
              >
                {isSubmitting ? "Saving ..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </div>
  );
}
