import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import AddIcon from "@material-ui/icons/Add";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import { useSnackbar } from "notistack";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { countries } from '../../../config/countries';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
  boxButton: {
    maxWidth: theme.spacing(24),
    height: theme.spacing(16),
    padding: theme.spacing(2),
    transition: "0.5s all ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer",
      color: theme.palette.info.main,
      boxShadow: `0 8px 6px -6px ${theme.palette.info.light}`,
    },
  },
  boxIcon: {
    display: "block",
    fontSize: 56,
    margin: "4px auto",
  },
  boxTitle: {
    textAlign: "center",
    fontSize: 16,
    margin: "8px auto",
  },

  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addButton: {
    color: theme.palette.info.light,
  },
  redText: {
    color: red[800],
  },
}));

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const CollectionPointSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  address_line_1: Yup.string().required("Address line  1 is required."),
  address_line_2: Yup.string(),
  county: Yup.string(),
  city: Yup.string().required("City is required."),
  postcode: Yup.string().required("Postcode is required."),
  country_code: Yup.string().required("Country is required."),
});

export default function CollectionPointAddForm({
  addCollectionPoint,
  boxButton,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hasTracking, setHasTracking] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    name: "",
    address_line_1: "",
    address_line_2: "",
    county: "",
    city: "",
    postcode: "",
    country_code: "",
    note: "",
  };

  return (
    <React.Fragment>
      {boxButton === true ? (
        <Paper
          elevation={1}
          className={classes.boxButton}
          onClick={handleClickOpen}
        >
          <AddIcon className={classes.boxIcon} />
          <Typography component="h6" className={classes.boxTitle}>
            Add Collection Point
          </Typography>
        </Paper>
      ) : (
        <IconButton
          size="small"
          aria-label="add"
          className={classes.addButton}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </IconButton>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={CollectionPointSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .post(`collection-points`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                addCollectionPoint(response.data.data);
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Add New Collection Point
            </DialogTitle>

            <DialogContent dividers={true}>

            <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="name"
                  label="Name"
                  type="text"
                  value={values.name}
                  helperText={errors ? errors.name : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.name && touched.name ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="address_line_1"
                  label="Address line 1"
                  type="text"
                  value={values.address_line_1}
                  helperText={errors ? errors.address_line_1 : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.address_line_1 && touched.address_line_1 ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="address_line_2"
                  label="Address line 2"
                  type="text"
                  value={values.address_line_2}
                  helperText={errors ? errors.address_line_2 : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.address_line_2 && touched.address_line_2 ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="county"
                  label="County"
                  type="text"
                  value={values.county}
                  helperText={errors ? errors.county : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.county && touched.county ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="city"
                  label="City"
                  type="text"
                  value={values.city}
                  helperText={errors ? errors.city : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.city && touched.city ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="postcode"
                  label="Postcode"
                  type="text"
                  value={values.postcode}
                  helperText={errors ? errors.postcode : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.postcode && touched.postcode ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl} >
                  <Autocomplete
                  id="country_code"
                  name="country_code"
                //  value={values.country_code}
                  options={countries}
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => (
                      <React.Fragment>
                        <span>{countryToFlag(option.code)}</span>
                        {option.label}
                      </React.Fragment>
                    )}
                    onChange={(e, value) => {
                      setFieldValue("country_code", value.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        helperText={errors ? errors.country_code : ""}
                        onBlur={handleBlur}
                        error={
                          errors &&
                          errors.country_code &&
                          touched.country_code
                            ? true
                            : null
                        }
                      />
                    )}
                  />
                </FormControl>


              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  multiline
                  rowsMax={4}
                  name="note"
                  label="Note"
                  type="text"
                  value={values.note}
                  helperText={errors ? errors.note : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.note && touched.note ? true : null}
                />
              </FormControl>
            </DialogContent>
 
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <SaveIcon />
                }
              >
                {isSubmitting ? "Saving ..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
