import React from "react";
import { CreateCategoryNav } from "../../components/categories/category-nav.component";
import CategoryCreateForm from "../../components/categories/category-create-form.component";

const CreateCategoryPage = () => {
  return (
    <React.Fragment>
      <CreateCategoryNav />
      <CategoryCreateForm />
    </React.Fragment>
  );
};

export default CreateCategoryPage;
