import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import OrderDeliveryItems from "./order-delivery-items.component";

const useStyles = makeStyles({
  root: {
    marginTop: 16,
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  marginTop: {
    marginTop: 12,
  },
});

export default function OrderDeliveryDetails({ delivery }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div>
          Delivery Type: <strong>{delivery.provider} - {delivery.service} ({delivery.speed})</strong>
        </div>
        <div>
          Cost: <strong>£{delivery.cost}</strong>
        </div>

        <div className={classes.marginTop}>Delivery items:</div>
        <OrderDeliveryItems items={delivery.items} />

        <div className={classes.marginTop}>Delivery address:</div>
        {delivery.address.name !== "" ? (
          <div>{delivery.address.name}</div>
        ) : (
          ""
        )}
        {delivery.address.address_line_1 !== "" ? (
          <div>{delivery.address.address_line_1}</div>
        ) : (
          ""
        )}
        {delivery.address.address_line_2 !== "" ? (
          <div>{delivery.address.address_line_2}</div>
        ) : (
          ""
        )}
        {delivery.address.city !== "" ? (
          <div>{delivery.address.city}</div>
        ) : (
          ""
        )}
        {delivery.address.county !== "" ? (
          <div>{delivery.address.county}</div>
        ) : (
          ""
        )}
        {delivery.address.postcode !== "" ? (
          <div>{delivery.address.postcode}</div>
        ) : (
          ""
        )}
        {delivery.address.country_code !== "" ? (
          <div>{delivery.address.country_code}</div>
        ) : (
          ""
        )}
      </CardContent>
    </Card>
  );
}
