import React from 'react';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import SkeletonCard from '../../components/common/skeleton.component';
import CollectionPointDetails from '../../components/shipping/collections/collection-point-details.component';


const CollectionPointDetilsPage = ({ match }) => {
  
  const { collection_point_id } = match.params;

    return (
          <CollectionPointDetails collectionPointId={collection_point_id} />
    );
  } 

export default CollectionPointDetilsPage;
