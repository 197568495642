import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { red, grey } from "@material-ui/core/colors";

const useStyles = makeStyles({
    container: {
        marginTop: 8,
        border: `1px solid ${grey[300]}`,
    },
  table: {
    minWidth: '100%',
  },
  borderRight: {
    borderRight: `1px solid ${grey[300]}`,
  }
});

export default function OrderItems({ items }) {
  const classes = useStyles();

  return (
    <TableContainer  className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.borderRight}>Item</TableCell>
            <TableCell align="center">Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" className={classes.borderRight}>
                  <div>{item.title}</div>
              <small>
          {item.variant_1_value !== null
            ? `(${item.variant_1_name}:${item.variant_1_value})`
            : ""}
        </small>
        <small>
          {item.variant_2_value !== null
            ? `(${item.variant_2_name}:${item.variant_2_value})`
            : ""}
        </small>
        <small>
          {item.variant_3_value !== null
            ? `(${item.variant_3_name}:${item.variant_3_value})`
            : ""}
        </small>
              </TableCell>
              <TableCell align="center">{item.quantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
