import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LocalMallIcon from "@material-ui/icons/LocalMall";

import Chip from "@material-ui/core/Chip";
import PublicIcon from "@material-ui/icons/Public";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  TableContainer: {
    minWidth: 600,
  },
  table: {
    width: '98%',
  },
  tableHeader: {
      backgroundColor: `${grey[300]}`,
  },
  columnBorder: {
    borderRight: `1px solid ${grey[300]}`,
  },
  chip: {
    padding: "8px",
    marginRight: '8px',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.info.light,
    }
  },
}));

export default function AvailablePackageSizes({ packageSizes }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Chip
        avatar={<Avatar style={{backgroundColor: 'white'}}>{packageSizes.length}</Avatar>}
        label={packageSizes.length > 1 ? 'Available Package Sizes' : 'Available Package Size'}
        className={classes.chip}
        onClick={handleClickOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
            maxWidth={"md"}
      >
        <DialogTitle id="scroll-dialog-title">Available Package Sizes</DialogTitle>
        <DialogContent dividers={true}>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>Format</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Dimensions (cm)</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Weight (g)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {packageSizes.map((packageSize) => (
                  <TableRow key={packageSize.id}>
                    <TableCell component="th" scope="row" className={classes.columnBorder}>
                      {packageSize.format}
                    </TableCell>
                    <TableCell align="center" className={classes.columnBorder}>{packageSize.length} x {packageSize.width} x {packageSize.height}</TableCell>
                    <TableCell align="center" className={classes.columnBorder}>{packageSize.min_weight} - {packageSize.max_weight}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
