import React from "react";
import { useHistory } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import { red, grey } from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import SkeletonCard from "../common/skeleton.component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },

  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid ${grey[300]}`,
  },
}));

export default function CustomerList() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [customers, setCustomers] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    console.log("run");
    setLoading(true);

    axios
      .get(`customers`)
      .then((response) => {
        setCustomers(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return loading ? (
    <SkeletonCard message="Loading customers ..." />
  ) : (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell width="30%" className={classes.tableHead} colSpan={2}>
                Name
              </TableCell>
              <TableCell width="30%" className={classes.tableHead}>
                Email Address
              </TableCell>
              <TableCell width="20%" className={classes.tableHead}>
                Phone
              </TableCell>
              <TableCell
                width="20%"
                align={"center"}
                className={classes.tableHead}
              >
                No of Orders
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((customer) => {
                return (
                  <TableRow
                    hover
                    style={{ cursor: "pointer" }}
                    role="checkbox"
                    tabIndex={-1}
                    key={customer.id}
                    onClick={() =>
                      history.push("/customers/" + customer.id + "/details")
                    }
                  >
                    <TableCell>
                      <Avatar>{customer.name.charAt(0)}</Avatar>
                    </TableCell>
                    <TableCell className={classes.columnBorder}>
                      {customer.name}
                    </TableCell>
                    <TableCell className={classes.columnBorder}>
                      {customer.email}
                    </TableCell>
                    <TableCell className={classes.columnBorder}>
                      {customer.phone}
                    </TableCell>
                    <TableCell align={"center"}>
                      {customer.no_of_orders}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={customers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
