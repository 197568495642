import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import PublicIcon from "@material-ui/icons/Public";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';



import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Typography from "@material-ui/core/Typography";

import PackageAddForm from "./package-add-form.component";
import PackageEditForm from "./package-edit-form.component";
import PackageDeleteForm from "./package-delete-form.component";
import SkeletonCard from "../../common/skeleton.component";

import { ShippingPackagesNav } from '../shipping-nav.component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    //  maxHeight: 440,
  },
  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid${grey[300]}`,
  },
  hoverPointer: {
    cursor: "pointer",
    borderRight: `1px solid${grey[300]}`,
  },
}));

export default function Packages() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [packageSizes, setPackageSizes] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    axios
      .get(`shipping-package-sizes`)
      .then((response) => {
        setPackageSizes(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const addPackageSize = (packageSize) => {
    packageSizes.push(packageSize);
    setPackageSizes(packageSizes.map((item) => item));
  };

  const updatePackageSize = (packageSize) => {
    setPackageSizes(
      packageSizes.map((item) => (item.id !== packageSize.id ? item : packageSize))
    );
  };

  const removePackageSize = (packageSizeId) => {
    setPackageSizes(packageSizes.filter((item) => item.id !== packageSizeId));
  };

 
  return loading ? (
    <SkeletonCard message="Loading package sizes ..." />
  ) : (

    packageSizes.length > 0 ?
    <React.Fragment>

      <ShippingPackagesNav />

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  width="22%"
                  className={classes.tableHead}
                >
                  Format
                </TableCell>
                <TableCell
                  align={"center"}
                  width="12%"
                  className={classes.tableHead}
                >
                  Length (cm)
                </TableCell>
                <TableCell
                  align={"center"}
                  width="12%"
                  className={classes.tableHead}
                >
                  Width (cm)
                </TableCell>
                <TableCell
                  align={"center"}
                  width="12%"
                  className={classes.tableHead}
                >
                  Height (cm)
                </TableCell>

                <TableCell
                  align={"center"}
                  width="16%"
                  className={classes.tableHead}
                >
                  Weight (g)
                </TableCell>

                <TableCell
                  align={"center"}
                  width="10%"
                  className={classes.tableHead}
                >
                  Available
                </TableCell>

                <TableCell align={"center"}
                  width="16%"
                  className={classes.tableHead}>
                     <PackageAddForm addPackageSize={addPackageSize}/>
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packageSizes.map((packageSize) => (
                <TableRow hover tabIndex={-1} key={packageSize.id}>
                  <TableCell
                    width="22%"
                    className={classes.columnBorder}
                  >
                    {packageSize.format}
                  </TableCell>

                  <TableCell
                    align={"center"}
                    width="12%"
                    className={classes.columnBorder}
                  >
                    {packageSize.length}
                  </TableCell>

                  <TableCell
                    align={"center"}
                    width="12%"
                    className={classes.columnBorder}
                  >
                    {packageSize.width}
                  </TableCell>

                  <TableCell
                    align={"center"}
                    width="12%"
                    className={classes.columnBorder}
                  >
                    {packageSize.height}
                  </TableCell>

                  <TableCell
                    align={"center"}
                    width="16%"
                    className={classes.columnBorder}
                  >
                    {packageSize.min_weight} <ArrowRightAltIcon fontSize="small"/> {packageSize.max_weight}
                  </TableCell>

                  <TableCell
                    width="10%"
                    align={"center"}
                    className={classes.columnBorder}
                  >
                    {packageSize.available === 1 ? (
                      <CheckIcon color="primary" fontSize="small" />
                    ) : (
                      <CloseIcon color="secondary" fontSize="small" />
                    )}
                  </TableCell>
                  
                  <TableCell
                    align={"center"}
                    width="16%"
                    className={classes.columnBorder}
                  >
                    <React.Fragment>
                        <PackageEditForm
                          packageSize={packageSize}
                          updatePackageSize={updatePackageSize}
                        />
                        <PackageDeleteForm
                          packageSize={packageSize}
                          removePackageSize={removePackageSize}
                        /> 
                      </React.Fragment>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment> : <PackageAddForm addPackageSize={addPackageSize} boxButton={true}/>
  );
}
