import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import PublicIcon from "@material-ui/icons/Public";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

import ShopTwoIcon from "@material-ui/icons/ShopTwo";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import Button from "@material-ui/core/Button";

import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Typography from "@material-ui/core/Typography";

import { ShippingOptionsNav } from "../shipping-nav.component";
import CollectionPointAddForm from "./collection-point-add-form.component";
import OptionEditForm from "./collection-point-edit-form.component";
import OptionDeleteForm from "./option-delete-form.component";
import SkeletonCard from "../../common/skeleton.component";
import ShippingProductsForm from "./shipping-products.component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    //  maxHeight: 440,
  },
  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  location: {
    padding: theme.spacing(2),
  },
  hoverPointer: {
    cursor: "pointer",
    borderRight: `1px solid${grey[300]}`,
  },
  button: {
    marginBottom: 8,
    marginRight: 8,
  },
}));

export default function CollectionPoints() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [locations, setLocations] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    axios
      .get(`collection-points`)
      .then((response) => {
        setLocations(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const addCollectionPoint = (location) => {
    locations.push(location);
    setLocations(locations.map((item) => item));
  };

  const updateShippingOption = (shippingOption) => {
    // setOptions(
    //   options.map((item) =>
    //     item.id !== shippingOption.id ? item : shippingOption
    //   )
    // );
  };

  const removeShippingOption = (shippingOptionId) => {
    // setOptions(options.filter((item) => item.id !== shippingOptionId));
  };

  return loading ? (
    <SkeletonCard message="Loading shipping options ..." />
  ) : locations.length > 0 ? (
    <React.Fragment>
      <ShippingOptionsNav />

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>Name</TableCell>

                <TableCell className={classes.tableHead}>Address</TableCell>

                <TableCell align={"center"} className={classes.tableHead}>
                  Postcode
                </TableCell>

                <TableCell align={"center"} className={classes.tableHead}>
                  No of Rates
                </TableCell>

                <TableCell align={"center"} className={classes.tableHead}>Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.map((location) => (
                <TableRow hover tabIndex={-1} key={location.id}>
                  <TableCell className={classes.columnBorder}>
                    {location.note === null ||
                    location.note === "" ? (
                      <Chip
                        variant="outlined"
                        label={location.name}
                        onClick={() =>
                          history.push(
                            "/settings/collection-points/" + location.id + "/details"
                          )
                        }
                      />
                    ) : (
                      <Tooltip title={location.note} placement="top">
                        <Chip label={location.name} />
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell className={classes.columnBorder}>
                   
                    <div>
                      {location.address_line_1} {location.address_line_2} {location.county}<br/>
                    {location.city} {location.postcode}
                    </div>
                  
                

                  </TableCell>

                  <TableCell align={"center"} className={classes.columnBorder}>
                    {location.country_code}
                  </TableCell>

                  <TableCell align={"center"} className={classes.columnBorder}>
                 {/* <ShippingProductsForm shippingOption={shippingOption} /> */}
                  </TableCell>

                  <TableCell align={"center"} className={classes.columnBorder}>
                    {location.active === 1 ? (
                      <CheckIcon color="primary" fontSize="small" />
                    ) : (
                      <CloseIcon color="secondary" fontSize="small" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CollectionPointAddForm addCollectionPoint={addCollectionPoint} boxButton={false} />
    </React.Fragment>
  ) : (
    <CollectionPointAddForm addCollectionPoint={addCollectionPoint} boxButton={true} />
  );
}
