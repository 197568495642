import React from "react";
import { useHistory } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { red, grey } from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import SkeletonCard from "../common/skeleton.component";

import OrderDeliveryDetails from "./order-delivery-details.component";
import OrderCollectionDetails from "./order-collection-details.component";
import OrderItems from "./order-items.component";
import PaymentDetails from "./order-payment-details.component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },

  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid${grey[300]}`,
  },

  cardHeader: {
    backgroundColor: grey[300],
  },
  avatar: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.info.light,
  },
  mt12: {
    marginTop: 12,
  },
}));

export default function OrderDetails({ orderId }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [order, setOrder] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    console.log("run");
    setLoading(true);

    axios
      .get(`orders/${orderId}`)
      .then((response) => {
        setOrder(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  console.log(order);

  return order.length <= 0 ? (
    <SkeletonCard message="Loading order ..." />
  ) : (
    <Paper className={classes.root}>
      <Card className={classes.root} variant="outlined">
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              O
            </Avatar>
          }
          title={<Typography variant="h6">Order Details</Typography>}
          subheader={order.ref}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div>Date: {order.created_at}</div>
              <div>Name: {order.name}</div>
              <div>Email Address: {order.email}</div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <strong>Order items:</strong>
              <OrderItems items={order.items} />

              <div className={classes.mt12}>
                <strong>Payment details:</strong>
              </div>
              <PaymentDetails payment={order.payment} />
            </Grid>
            <Grid item xs={12} md={6}>
              <strong>
                This order has following {order.deliveries.length}{" "}
                {order.deliveries.length > 1 ? "deliveries" : "delivery"}:
              </strong>
              {order.deliveries.map((delivery, index) => {
                if (delivery.method === "collection") {
                  return (
                    <OrderCollectionDetails key={index} collection={delivery} />
                  );
                } else if (delivery.method === "delivery") {
                  return (
                    <OrderDeliveryDetails key={index} delivery={delivery} />
                  );
                }
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
}
