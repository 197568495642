import React, { useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { styled } from "@material-ui/core/styles";
import * as Yup from "yup";

import Paper from "@material-ui/core/Paper";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, blue } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import SaveAltIcon from "@material-ui/icons/SaveAlt";


import { fade } from "@material-ui/core/styles/colorManipulator";


import FormControl from "@material-ui/core/FormControl";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";

import LinearProgress from "@material-ui/core/LinearProgress";
import { SnackbarProvider, useSnackbar } from "notistack";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    maxWidth: "600px",
  },
  formName: {
    margin: theme.spacing(1),
    width: "100%",
    display: "block",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  bapro: {
    width: "200px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: fade(blue[100], 0.5),
    color: blue[800],
  },


  formControl: {
    // margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("First name is required."),
    email: Yup.string().email().required('Email Address is required'),
    password: Yup.string().required('Password is required'),
    password_confirmation: Yup.string().required('Confirm password is required'),
});

const UserCreateForm = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };




  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();



  return (


    


   


    <Card className={classes.card}>






      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
          password_confirmation: '',
        }}
        validationSchema={UserSchema}
        onSubmit={(values, actions) => {

          //  console.log('>>>>>>>');

        //  console.log(JSON.stringify(values));

          setSuccess(false);
          setLoading(true);


          axios
            .post(`auth/users`, values, {
              headers: { Authorization: "Bearer " + localStorage.getItem('access_token') }
            })
            .then((response) => {
              

              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                history.push(`/settings/users/${response.data.data.id}/details`);
              }

            })
            .catch(function (error) {
              console.log(error.response);

            //  console.log(JSON.stringify(values));


              setSuccess(false);
              setLoading(false);
           

              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
           
          <Form noValidate autoComplete="off">
            <CardHeader
              subheader="Create a new user here"
              title="User"
            />
            <Divider />
            <CardContent>
              

                <Grid container spacing={3}>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="name"
                      label="Name"
                      value={values.name}
                      helperText={errors ? errors.name : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.name && touched.name ? true : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="email"
                      label="Email Address"
                      value={values.email}
                      helperText={errors ? errors.email : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.email && touched.email ? true : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      type="password"
                      name="password"
                      label="Password"
                      value={values.password}
                      helperText={errors ? errors.password : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.password && touched.password ? true : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      type="password"
                      name="password_confirmation"
                      label="Confirm Password"
                      value={values.password_confirmation}
                      helperText={errors ? errors.password_confirmation : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.password && touched.password ? true : null
                      }
                    />
                  </FormControl>
                </Grid>
             </Grid>
            </CardContent>
            <Divider />
            <CardActions>
 
                  <div className={classes.wrapper}>
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={loading}
                      onClick={handleSubmit}
                      //  className={classes.submitButton}
                      startIcon={<SaveIcon />}
                    >
                      {isSubmitting ? "Saving ..." : "Save"}
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
               
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default UserCreateForm;
