import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { auth } from '../../auth';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const Profile = () => {
  const classes = useStyles();
  const user = JSON.parse(auth().user);
 
  return (
    <div className={classes.root}>
          <h3>Profile</h3>
          { user.name }
    </div>
  );
};

export default Profile;
