import React from 'react';
import { useHistory } from "react-router-dom";
import { fade, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import { grey } from "@material-ui/core/colors";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },

  tableHead: {
      backgroundColor: fade(theme.palette.primary.dark, 0.85),
      color: theme.palette.info.light,
  },
  tableRow: {
    'hover': {
      cursor: 'pointer'
    }
  },
  columnBorder: {
    borderRight: `1px solid ${grey[300]}`,
  },
}));

export default function UserList ({data}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <TableCell
                  align="left"
                  className={classes.tableHead}
                >Name</TableCell>
                <TableCell
                  align="left"
                  className={classes.tableHead}
                >Email Address</TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHead}
                >Created At</TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHead}
                >Status</TableCell>
         
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => {
               console.log(user);
              return (
               
                <TableRow hover style={{ cursor: 'pointer' }} role="checkbox" tabIndex={-1} key={user.id} onClick={ () => history.push('/settings/users/'+user.id+'/details')}>
                  <TableCell  className={classes.columnBorder}>
                       { user.name }
                      </TableCell>
                      <TableCell  className={classes.columnBorder}>
                      { user.email }
                      </TableCell>
                      <TableCell  align={'center'} className={classes.columnBorder}>
                      { user.created_at }
                      </TableCell>
                      <TableCell  align={'center'}>
                      { user.disabled ? <Chip
        size="small"
        label="Disable"
        color="secondary"
      /> : <Chip
      size="small"
      label="Active"
      color="primary"
    /> }
                      </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
