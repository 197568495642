import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import RouteWithLayout from '../components/layouts/layout.component';

import PublicRoute from './public.route';
import ProtectedRoute from './protected.route';

import PrivateLayout from '../layouts/private.layout';
import PublicLayout from '../layouts/public.layout';


import SignupForm from '../pages/form.view';
import RecipeReviewCard from '../pages/test.view';

// tables 
import SimpleTable from '../pages/tables/simple.table';
import EnhancedTable from '../pages/tables/enhanced.table';
import DenseTable from '../pages/tables/dense.table';
import FixHeadTable from '../pages/tables/fix-head.table';
import MaterialTable from '../pages/tables/material.table';

import SignIn from '../pages/auth/sign-in.page';
import Profile from '../pages/auth/profile.page';
import ForgotPassword from '../pages/auth/forgot-password.page';
import ResetPassword from '../pages/auth/reset-password.page';



import Help from '../pages/help.view';
import NotFound from '../pages/errors/404';

import Dashboard from '../pages/dashboards/dashboard.view';

import OrderDetailsPage from '../pages/orders/order-details.page';
import OrderListPage from '../pages/orders/order-list.page';

import CustomerListPage from '../pages/customers/customer-list.page';
import CustomerDetailsPage from '../pages/customers/customer-details.page';

import CategoryListPage from '../pages/categories/category-list.page';
import CreateCategoryPage from '../pages/categories/create-category.page';
import CategoryDetailsPage from '../pages/categories/category-details.page';


import ProductListPage from '../pages/products/product-list.page';
import CreateProductPage from '../pages/products/create-product.page';
import ProductDetailsPage from '../pages/products/product-details.page';

import ShippingSettingsPage from '../pages/shipping/shipping-settings.page';
import PackageSizesPage from '../pages/shipping/package-sizes.page';
import ShippingOptionsPage from '../pages/shipping/shipping-options.page';
import ShippingZoneDetailsPage from '../pages/shipping/shipping-zone-details.page';
import ShippingZonesPage from '../pages/shipping/shipping-zones.page';

import CollectionPointDetailsPage from '../pages/shipping/collection-point-details.page';
import CollectionPointsPage from '../pages/shipping/collection-points.page';



/* Roles */
// import ListofRoles from '../pages/auth/roles/index';
// import CreateRole from '../pages/auth/roles/create';
// import EditRole from '../pages/auth/roles/edit';
// import ShowRole from '../pages/auth/roles/show';


/* Users */
import UserListPage from '../pages/users/user-list.page';
import CreateUserPage from '../pages/users/create-user.page';
import UserDetailsPage from '../pages/users/user-details.page';


const Routes = () => {
  return (
    <Switch>

      <Redirect exact from="/" to="/dashboard" />

      <PublicRoute exact path="/forgot-password" title="Login" component={ForgotPassword} layout={PublicLayout} />
      <PublicRoute exact path="/reset/:token/password" title="Login" component={ResetPassword} layout={PublicLayout} />

      <PublicRoute exact path="/login" title="Login" component={SignIn} layout={PublicLayout} />
      <ProtectedRoute exact path="/profile" title="Profile" component={Profile} layout={PrivateLayout} />

      <ProtectedRoute exact path="/dashboard" title="Dashboard" component={Dashboard} layout={PrivateLayout} />

      <ProtectedRoute exact path="/orders/:order_id/details" title="Order Details" component={OrderDetailsPage} layout={PrivateLayout} />
      <ProtectedRoute exact path="/orders" title="Orders" component={OrderListPage} layout={PrivateLayout} />
      
      <ProtectedRoute exact path="/customers/:customer_id/details" title="Customer Details" component={CustomerDetailsPage} layout={PrivateLayout} />
      <ProtectedRoute exact path="/customers" title="Customers" component={CustomerListPage} layout={PrivateLayout} />
      
      <ProtectedRoute exact path="/categories/:category_id/details" title="Category Details" component={ CategoryDetailsPage } layout={PrivateLayout} />
      <ProtectedRoute exact path="/categories/create" title="New Category" component={ CreateCategoryPage } layout={PrivateLayout} />
      <ProtectedRoute exact path="/categories" title="Categories" component={CategoryListPage} layout={PrivateLayout} />

      <ProtectedRoute exact path="/products/:product_id/details" title="Product Details" component={ ProductDetailsPage } layout={PrivateLayout} />
      <ProtectedRoute exact path="/products/create" title="New Product" component={ CreateProductPage } layout={PrivateLayout} />
      <ProtectedRoute exact path="/products" title="Products" component={ProductListPage} layout={PrivateLayout} />
      
      <ProtectedRoute exact path="/settings/shipping-zones/:zone_id/details" title="Shipping Zone Details" component={ShippingZoneDetailsPage} layout={PrivateLayout} />
      <ProtectedRoute exact path="/settings/shipping-zones" title="Shipping Zones" component={ShippingZonesPage} layout={PrivateLayout} />
      <ProtectedRoute exact path="/settings/shipping-options" title="Shipping Options" component={ShippingOptionsPage} layout={PrivateLayout} />
      <ProtectedRoute exact path="/settings/shipping-packages" title="Shipping Packages" component={PackageSizesPage} layout={PrivateLayout} />
      <ProtectedRoute exact path="/settings/collection-points/:collection_point_id/details" title="Collection Point Details" component={CollectionPointDetailsPage} layout={PrivateLayout} />
      <ProtectedRoute exact path="/settings/collection-points" title="Collection Points" component={CollectionPointsPage} layout={PrivateLayout} />
      <ProtectedRoute exact path="/settings/shippings" title="Shippings" component={ShippingSettingsPage} layout={PrivateLayout} />

      <ProtectedRoute exact path="/settings/users/:user_id/details" title="User Details" component={ UserDetailsPage } layout={PrivateLayout} />
      <ProtectedRoute exact path="/settings/users/create" title="New User" component={ CreateUserPage } layout={PrivateLayout} />
      <ProtectedRoute exact path="/settings/users" title="Users" component={ UserListPage } layout={PrivateLayout} />


      <ProtectedRoute exact path="/not-found" title="404" component={NotFound} layout={PrivateLayout} />
      <Redirect to="/not-found" />

    </Switch>
  );
};

export default Routes;
