import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import VariantTypeCreateForm from "./variant-type-create-form.component";
import VariantTypeEditForm from "./variant-type-edit-form.component";
import VariantTypeDeleteForm from "./variant-type-delete-form.component";

import VariantAddForm from "./variant-add-form.component";
import VariantEditForm from "./variant-edit-form.component";
import VariantDeleteForm from "./variant-delete-form.component";

import { red, grey } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import SkeletonCard from '../../common/skeleton.component';

const useStyles = makeStyles({
  table: {
    minWidth: 1450,
  },
  columnBorder: {
    borderRight: `1px solid${grey[300]}`,
  },
});

export default function ProductVariants({ productId }) {
  const classes = useStyles();

  const [loadVariants, setLoadVariants] = React.useState(true);
  const [variantTypes, setVariantTypes] = React.useState([]);
  const [variants, setVariants] = React.useState([]);

  React.useEffect(() => {
    console.log("run");
    console.log(loadVariants);
   
if(loadVariants) {
  axios
  .get(`products/${productId}/variants`)
  .then((response) => {
    console.log(response);

    // convert to array
  let productVariantTypes = [];
  Object.keys(response.data.data.types).map((key) => {
    productVariantTypes[key] = response.data.data.types[key];
  });


    setVariantTypes(productVariantTypes);
    setVariants(response.data.data.values);
    setLoadVariants(false);
  })
  .catch((error) => {
    console.log(error);
    
  });
}
    
  }, [loadVariants]);

  

  

  const createVariantType = (variantType) => {
    // variantTypes.splice(variantType.variant_no, 0, variantType);

    // console.log(variantTypes);

    // console.log("create >>>", variantType);

    // let newVariantTypes = [];
    // variantTypes.map((variant_type) => {
    //   newVariantTypes[variant_type.variant_no] = variant_type;
    // });

    // setVariantTypes(newVariantTypes);
    setLoadVariants(true);

    console.log(variantTypes);
  };

  const updateVariantType = (variantType) => {
    // variantTypes.splice(variantType.variant_no, 1);

    // console.log("update >>>", variantType);

    // let newVariantTypes = [];
    // variantTypes.map((variant_type) => {
    //   if (variant_type.id === variantType.id) {
    //     newVariantTypes[variant_type.variant_no] = variantType;
    //   } else {
    //     newVariantTypes[variant_type.variant_no] = variant_type;
    //   }
    // });

    // console.log(newVariantTypes);

    // setVariantTypes(newVariantTypes);

    setLoadVariants(true);
  };

  const removeVariantType = (variantTypeId, variantNo) => {

    setLoadVariants(true);

    // console.log(variantTypes);

    // let newVariantTypes = [];
    // variantTypes.map((variant_type) => {
    //   if (variant_type.id !== variantTypeId) {
    //     newVariantTypes[variant_type.variant_no] = variant_type;
    //   }
    // });

    // setVariantTypes(newVariantTypes);

    // const newVariants = variants.map((item) => {
    //   item["variant_" + variantNo + "_value"] = null;
    //   return item;
    // });

    // setVariants(newVariants);

  };

  const addVariant = (variant) => {
    //setLoadVariants(true);
    variants.push(variant);
    setVariants(variants.map((item) => item));
  };

  const updateVariant = (variant) => {
   // setLoadVariants(true);
    setVariants(
      variants.map((item) => (item.id !== variant.id ? item : variant))
    );
  };

  const removeVariant = (variantId) => {
   // setLoadVariants(true);
    setVariants(
      variants.filter((item) => {
        if (item.id !== variantId) {
          console.log(item);
          return item;
        }
      })
    );
  };

  return loadVariants ? <SkeletonCard /> : (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="table" size="small">
        <TableHead>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              rowSpan={2}
              className={classes.columnBorder}
            >
              SKU
            </TableCell>
            <TableCell
              align="center"
              rowSpan={2}
              className={classes.columnBorder}
            >
              Price
            </TableCell>
            <TableCell
              align="center"
              rowSpan={2}
              className={classes.columnBorder}
            >
              Weight (gm)
            </TableCell>
            <TableCell
              align="center"
              rowSpan={2}
              className={classes.columnBorder}
            >
              Dimensions (cm)
            </TableCell>
            <TableCell
              align="center"
              colSpan={2}
              className={classes.columnBorder}
            >
              Shipping
            </TableCell>

            <TableCell align="center" className={classes.columnBorder}>
              {variantTypes[1] === undefined
                ? "Variant 1"
                : variantTypes[1].name}
            </TableCell>

            <TableCell align="center" className={classes.columnBorder}>
              {variantTypes[2] === undefined
                ? "Variant 2"
                : variantTypes[2].name}
            </TableCell>

            <TableCell align="center" className={classes.columnBorder}>
              {variantTypes[3] === undefined
                ? "Variant 3"
                : variantTypes[3].name}
            </TableCell>

            <TableCell
              align="center"
              rowSpan={2}
              className={classes.columnBorder}
            >
              Stock
            </TableCell>

            <TableCell align="center" rowSpan={2}>
              <VariantAddForm
                productId={productId}
                variantTypes={variantTypes}
                addVariant={addVariant}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="center" className={classes.columnBorder}>
              Separated
            </TableCell>
            <TableCell align="center" className={classes.columnBorder}>
              Additional Cost
            </TableCell>

            <TableCell
              align="center"
              className={classes.columnBorder}
              width={130}
            >
              {variantTypes[1] === undefined ? (
                <VariantTypeCreateForm
                  productId={productId}
                  variantNo={1}
                  createVariantType={createVariantType}
                />
              ) : (
                <div>
                  <VariantTypeEditForm
                    variantType={variantTypes[1]}
                    updateVariantType={updateVariantType}
                  />
                  <VariantTypeDeleteForm
                    productId={productId}
                    variantTypeId={variantTypes[1].id}
                    variantNo={1}
                    removeVariantType={removeVariantType}
                  />
                </div>
              )}
            </TableCell>

            <TableCell
              align="center"
              className={classes.columnBorder}
              width={130}
            >
              {variantTypes[2] === undefined ? (
                <VariantTypeCreateForm
                  productId={productId}
                  variantNo={2}
                  createVariantType={createVariantType}
                />
              ) : (
                <div>
                  <VariantTypeEditForm
                    variantType={variantTypes[2]}
                    updateVariantType={updateVariantType}
                  />
                  <VariantTypeDeleteForm
                    productId={productId}
                    variantTypeId={variantTypes[2].id}
                    variantNo={2}
                    removeVariantType={removeVariantType}
                  />
                </div>
              )}
            </TableCell>

            <TableCell
              align="center"
              className={classes.columnBorder}
              width={130}
            >
              {variantTypes[3] === undefined ? (
                <VariantTypeCreateForm
                  productId={productId}
                  variantNo={3}
                  createVariantType={createVariantType}
                />
              ) : (
                <div>
                  <VariantTypeEditForm
                    variantType={variantTypes[3]}
                    updateVariantType={updateVariantType}
                  />
                  <VariantTypeDeleteForm
                    productId={productId}
                    variantTypeId={variantTypes[3].id}
                    variantNo={3}
                    removeVariantType={removeVariantType}
                  />
                </div>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variants.map((variant) => (
            <TableRow key={variant.id}>
              <TableCell
                component="th"
                scope="row"
                className={classes.columnBorder}
              >
                {variant.sku}
              </TableCell>
              <TableCell align="right" className={classes.columnBorder}>
                {variant.price}
              </TableCell>
              <TableCell align="right" className={classes.columnBorder}>
                {variant.weight}
              </TableCell>
              <TableCell
                align="center"
                className={classes.columnBorder}
                width={200}
              >
                {variant.length} x {variant.width} x {variant.height}
              </TableCell>

              
                

                  <TableCell align="center" className={classes.columnBorder}>
                    {variant.separated_shipping_required}
                  </TableCell>

                  <TableCell align="center" className={classes.columnBorder}>
                    {variant.additional_shipping_cost}
                  </TableCell>
                
              

              <TableCell
                key="c-1"
                align="center"
                className={classes.columnBorder}
              >
                {variant.variant_1_value ?? "--"}
              </TableCell>
              <TableCell
                key="c-2"
                align="center"
                className={classes.columnBorder}
              >
                {variant.variant_2_value ?? "--"}
              </TableCell>
              <TableCell
                key="c-3"
                align="center"
                className={classes.columnBorder}
              >
                {variant.variant_3_value ?? "--"}
              </TableCell>

              <TableCell align="center" className={classes.columnBorder}>
                {variant.stock ? (
                  variant.stock
                ) : (
                  <CloseIcon color="secondary" />
                )}
              </TableCell>

              <TableCell align="center" width={130}>
                <VariantEditForm
                  variant={variant}
                  variantTypes={variantTypes}
                  updateVariant={updateVariant}
                />
                <VariantDeleteForm
                  variant={variant}
                  removeVariant={removeVariant}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
