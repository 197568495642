import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { CategoryNav } from "../../components/categories/category-nav.component";
import CategoryUpdateForm from "../../components/categories/category-update-form.component";
import CircularProgress from "../../components/common/circular-progress.component";
import CategoryProductList from "../../components/categories/category-product-list.component";

const CustomerDetailsPage = ({match}) =>  {

  const { customer_id } = match.params;

      return (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
            Customer Details ({customer_id})
            </Grid>
            <Grid item xs={12} md={6}>
             
            </Grid>
          </Grid>
        </React.Fragment>
      );
}

export default CustomerDetailsPage;
