export const tinymce = {
    height: 400,
    menubar: false,
    plugins: [
      "advlist autolink lists link image",
      "charmap print preview anchor help",
      "searchreplace visualblocks code",
      "insertdatetime media table paste wordcount",
    ],
    toolbar:
      "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
  }