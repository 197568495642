import React from "react";
import { CreateUserNav } from "../../components/users/user-nav.component";
import UserCreateForm from '../../components/users/user-create-form.component';

export default function CreateUserPage () {
    return (
      <div>
        <CreateUserNav />
        <UserCreateForm />
      </div>
    );
}
