import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import OrderList from '../../components/orders/order-list.component';

const useStyles = makeStyles(theme => ({
  root: {
   // padding: theme.spacing(4)
  }
}));

const OrderListPage = () => {
  const classes = useStyles();

  return (
   <OrderList />
  );
};

export default OrderListPage;
