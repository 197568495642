import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import PublicIcon from "@material-ui/icons/Public";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RoomIcon from '@material-ui/icons/Room';

import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import { countries } from '../../../config/countries';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
} from "@material-ui/core";


import CollectionPointEditForm from "./collection-point-edit-form.component";
// import ShippingZoneDeleteForm from "./shipping-zone-delete-form.component";
// import ShippingCountries from "./shipping-countries.component";
// import ShippingZoneProducts from './shipping-zone-products.component';
import SkeletonCard from "../../common/skeleton.component";

import CollectionRates from "./rates/collection-rates.component";



const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  address: {
    maxWidth: 360,
    padding: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid${grey[300]}`,
  },
  hoverPointer: {
    cursor: "pointer",
    borderRight: `1px solid${grey[300]}`,
  },

  cardHeader: {
    backgroundColor: grey[300],
  },
  avatar: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.info.light,
  },
}));

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

export default function CollectionPointDetails({ collectionPointId }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [collectionPoint, setCollectionPoint] = React.useState([]);
  const [loadCollectionPoint, setLoadCollectionPoint] = React.useState(true);

  const [
    activeCollectionPoints,
    setActiveCollectionPoints,
  ] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    if(loadCollectionPoint) {
      axios
      .get(`collection-points/${collectionPointId}`)
      .then((response) => {
        setCollectionPoint(response.data.data);
        setLoadCollectionPoint(false);
        setLoading(false);
      })
      .then((response) => {
        // active collection points
axios
.get(`active-collection-points`)
.then((response) => {
  setActiveCollectionPoints(response.data.data);
})
.catch((error) => {
  console.log(error);
});

      })
      .catch((error) => {
        console.log(error);
        setLoadCollectionPoint(false);
        setLoading(false);
      });
    }
  }, [loadCollectionPoint]);

  

  const updateCollectionPoint = () => {
    setLoadCollectionPoint(true);
  };

  const removeCollectionPoint = (zoneId) => {
    // setCollectionPoints(shippingZones.filter((item) => item.id !== zoneId));
  };

  return loading ? (
    ""
  ) : (
    <React.Fragment>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar aria-label="collection point" className={classes.avatar}>
              <RoomIcon fontSize="large" />
            </Avatar>
          }
          action={
            <CollectionPointEditForm
              collectionPoint={collectionPoint}
              updateCollectionPoint={updateCollectionPoint}
            />
          }
          subheader={`${
            collectionPoint.active === 1
              ? "Available to collect from this location" : "Not active"
          }`}
          title={
            <div>
              <Typography variant="h6">{`${collectionPoint.name}`} </Typography>
            </div>
          }
        />
        <Divider />
        <CardContent>

        <Typography
            variant="h6"
            style={{ marginTop: 8, marginBottom: 8 }}
            color="textSecondary"
          >
            Address
          </Typography>

          <Paper className={classes.address}>
            <div>{collectionPoint.address_line_1}</div>
            <div>{collectionPoint.address_line_2}</div>
            <div>{collectionPoint.county}</div>
            <div>{collectionPoint.city}</div>
            <div>{collectionPoint.postcode}</div>
            <div>{countryToFlag(collectionPoint.country_code)} {countries.find(country => country.code === collectionPoint.country_code).label}</div>
          </Paper>

         

          <Typography
            variant="h6"
            style={{ marginTop: 16 }}
            color="textSecondary"
          >
            Collection rates for {`${collectionPoint.name}`} 
          </Typography>
          <CollectionRates
            collectionPointId={collectionPointId}
            activeCollectionPoints={activeCollectionPoints}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
