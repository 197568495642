import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import PublicIcon from "@material-ui/icons/Public";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

import ShopTwoIcon from "@material-ui/icons/ShopTwo";


import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import Button from "@material-ui/core/Button";

import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Typography from "@material-ui/core/Typography";

import { ShippingOptionsNav } from '../shipping-nav.component';
import OptionAddForm from "./option-add-form.component";
import OptionEditForm from "./option-edit-form.component";
import OptionDeleteForm from "./option-delete-form.component";
import SkeletonCard from "../../common/skeleton.component";
import ShippingProductsForm from './shipping-products.component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    //  maxHeight: 440,
  },
  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid${grey[300]}`,
  },
  hoverPointer: {
    cursor: "pointer",
    borderRight: `1px solid${grey[300]}`,
  },
  button: {
    marginBottom: 8,
    marginRight: 8,
  }
}));

export default function ShippingOptions() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`shipping-options`)
      .then((response) => {
        setOptions(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const addShippingOption = (shippingOption) => {
    options.push(shippingOption);
    setOptions(options.map((item) => item));
  };

  const updateShippingOption = (shippingOption) => {
    setOptions(
      options.map((item) =>
        item.id !== shippingOption.id ? item : shippingOption
      )
    );
  };

  const removeShippingOption = (shippingOptionId) => {
    setOptions(options.filter((item) => item.id !== shippingOptionId));
  };

  return loading ? (
    <SkeletonCard message="Loading shipping options ..." />
  ) : options.length > 0 ? (
    <React.Fragment>

<ShippingOptionsNav />


      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>Proviver</TableCell>

                <TableCell className={classes.tableHead}>Service</TableCell>

                <TableCell align={"center"} className={classes.tableHead}>
                  Weight (g)
                </TableCell>

                <TableCell align={"center"} className={classes.tableHead}>
                  Available
                </TableCell>

                <TableCell align={"center"} className={classes.tableHead}>Products</TableCell>

                <TableCell
                  align={"center"}
                  width="140"
                  className={classes.tableHead}
                >
                  <OptionAddForm addShippingOption={addShippingOption} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {options.map((shippingOption) => (
                <TableRow hover tabIndex={-1} key={shippingOption.id}>
                  <TableCell className={classes.columnBorder}>
                    {shippingOption.note === null ||
                    shippingOption.note === "" ? (
                      <Chip
                        variant="outlined"
                        label={shippingOption.provider}
                      />
                    ) : (
                      <Tooltip title={shippingOption.note} placement="top">
                        <Chip label={shippingOption.provider} />
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell className={classes.columnBorder}>
                    {shippingOption.service}
                    <br />({shippingOption.speed}{shippingOption.has_tracking === 1 ? (
                      ' - '+shippingOption.tracking_type
                    ) : ''})

                  </TableCell>

                  <TableCell align={"center"} className={classes.columnBorder}>
                    {shippingOption.max_weight > 0 ? (
                      <span>
                        {shippingOption.min_weight}{" "}
                        <ArrowRightAltIcon fontSize="small" />{" "}
                        {shippingOption.max_weight}
                      </span>
                    ) : (
                      "--"
                    )}
                  </TableCell>

                  <TableCell align={"center"} className={classes.columnBorder}>
                    {shippingOption.available === 1 ? (
                      <CheckIcon color="primary" fontSize="small" />
                    ) : (
                      <CloseIcon color="secondary" fontSize="small" />
                    )}
                  </TableCell>

                  <TableCell align={"center"} className={classes.columnBorder}>
                 <ShippingProductsForm shippingOption={shippingOption} />
                  </TableCell>

                  <TableCell
                    align={"center"}
                    width="140"
                    className={classes.columnBorder}
                  >
                    <React.Fragment>
                      <OptionEditForm
                        shippingOption={shippingOption}
                        updateShippingOption={updateShippingOption}
                      />
                      <OptionDeleteForm
                        shippingOption={shippingOption}
                        removeShippingOption={removeShippingOption}
                      />
                    </React.Fragment>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  ) : (
    <OptionAddForm addShippingOption={addShippingOption} boxButton={true} />
  );
}
