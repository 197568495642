import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { ProductNav } from "../../components/products/product-nav.component";
import ProductDetails from "../../components/products/product-details.component";
import ProductFeatureImage from "../../components/products/feature-image/product-feature-image.component";
import ProductImages from "../../components/products/images/product-images.component";
import ProductVariants from "../../components/products/variants/product-variants.component";
import ProductShipping from '../../components/products/product-shipping.component';
import CircularProgress from "../../components/common/circular-progress.component";


class ProductDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: {},
      product: {},
    };
  }

  componentDidMount = () => {
    axios
      .get(`categories`)
      .then((response) => {
        let categories = response.data.data.map((item) => {
          return {
            id: item.id,
            title: item.title,
          };
        });

        this.setState({
          ...this.state,
          categories: categories,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const {
      match: { params },
    } = this.props;

    if (params.product_id !== undefined) {
      axios
        .get(`products/${params.product_id}`)
        .then((response) => {
          this.setState({
            ...this.state,
            product: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    if (Object.keys(this.state.product).length > 0) {
      return (
        <React.Fragment>
          <ProductNav />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ProductDetails
                categories={this.state.categories}
                product={this.state.product}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <ProductFeatureImage
                productId={this.state.product.id}
                featureImage={this.state.product.feature_image}
              />

              <ProductImages
                productId={this.state.product.id}
                images={this.state.product.images}
              />
            </Grid>

            <Grid item xs={12}>
              <ProductVariants
                productId={this.state.product.id}
                //productVariants={this.state.product.variants}
              />

            
            </Grid>

            <Grid item xs={12}>
              <ProductShipping productId={this.state.product.id} productTitle={this.state.product.title}/>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default ProductDetailsPage;
