import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";

import { Formik } from "formik";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  imageInput: {
    display: "none",
  },
  redText: {
    color: red[800],
  },
}));

// const ImageSchema = Yup.object().shape({
//   description: Yup.string().required("required"),
//   image: Yup.mixed().required(),
// });

export default function ImageEditForm({ image, updateImage }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [previewImage, setPreviewImage] = React.useState(image.location);

  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    description: image.description === null ? "" : image.description,
    image: null,
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<EditIcon />}
        onClick={handleClickOpen}
      >
        Edit
      </Button>

      <Formik
        initialValues={initialValues}
        //  validationSchema={ImageSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          let formData = new FormData();
          formData.append("description", values.description);
          formData.append("image", values.image);
          formData.append("_method", "put");

          axios
            .post(`products/${image.product_id}/images/${image.id}`, formData)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                updateImage(response.data.data);
                setPreviewImage(response.data.data.location);
              }
            })
            .catch(function (error) {
              console.log(error.response);
              setLoading(false);
              // actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Upload Image</DialogTitle>
            <DialogContent dividers>
              <DialogContentText></DialogContentText>

              <FormControl fullWidth>
                <TextField
                  name="description"
                  label="Description"
                  type="text"
                  value={values.description}
                  helperText={errors ? errors.description : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.description && touched.description
                      ? true
                      : null
                  }
                />
              </FormControl>

              {previewImage !== null ? (
                <Paper
                  variant="outlined"
                  style={{ width: "100%", marginTop: "24px" }}
                >
                  <img width="100%" src={previewImage} alt="" />
                </Paper>
              ) : null}

              <FormControl fullWidth>
                <input
                  accept="image/*"
                  className={classes.imageInput}
                  id="contained-button-file"
                  type="file"
                  name="image"
                  onChange={(event) => {
                    event.preventDefault();

                    let reader = new FileReader();

                    reader.onloadend = () => {
                      console.log(reader.result);
                      setPreviewImage(reader.result);
                    };

                    reader.readAsDataURL(event.currentTarget.files[0]);

                    setFieldValue("image", event.currentTarget.files[0]);
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="div"
                    style={{ marginTop: "16px" }}
                    startIcon={<PhotoCamera />}
                  >
                    Select new Picture
                  </Button>
                </label>

                <div className={classes.redText}>
                  {errors ? errors.image : ""}
                </div>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <EditIcon />
                }
              >
                {isSubmitting ? "Updating ..." : "Update"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
