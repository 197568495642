import React from "react";
import { useHistory } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import { red, grey } from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import SkeletonCard from "../common/skeleton.component";
import { useSnackbar } from "notistack";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },

  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid${grey[300]}`,
  },
}));

export default function OrderList() {
  const classes = useStyles();
  const [loadAllOrders, setLoadAllOrders] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orders, setOrders] = React.useState([]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();


  React.useEffect(() => {
    console.log("run");
    setLoading(true);
    
    if(loadAllOrders) {
      axios
      .get(`orders`)
      .then((response) => {
        setOrders(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

      setLoadAllOrders(false);
    }

    
  }, [loadAllOrders]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleReloadOrders = () => {
    setLoadAllOrders(true);
  }


  const searchOrders = (e) => {

    if (e.keyCode === 13 && e.target.value !== '') {
        axios
        .post(`filter-order-list`, {search: e.target.value})
        .then((response) => {
          if(response.data.data.length > 0) {
            setOrders(response.data.data);
          } else {
            enqueueSnackbar(`No order found.`, {
              variant: 'info',
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } 
  };

  return loading ? (
    <SkeletonCard message="Loading orders ..." />
  ) : (
    <div>
         <TextField
            style={{ marginBottom: "12px" }}
            id="search_orders"
            name="search_orders"
            label="Search Orders ..."
            onKeyUp={(e) => searchOrders(e)}
          />
          <Button variant="contained" onClick={handleReloadOrders} style={{margin: '8px'}}>Load All Orders</Button>
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Ref. No.</TableCell>
              <TableCell className={classes.tableHead}>Date</TableCell>
              <TableCell className={classes.tableHead}>Customer</TableCell>
              <TableCell align={"center"} className={classes.tableHead}>
                No of Items
              </TableCell>
              <TableCell align={"center"} className={classes.tableHead}>
                Payment
              </TableCell>
              <TableCell align={"center"} className={classes.tableHead}>
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order) => {
                return (
                  <TableRow
                    hover
                    style={{ cursor: "pointer" }}
                    role="checkbox"
                    tabIndex={-1}
                    key={order.id}
                    onClick={() =>
                      history.push("/orders/" + order.id + "/details")
                    }
                  >
                    <TableCell className={classes.columnBorder}>
                      {order.ref}
                    </TableCell>
                    <TableCell className={classes.columnBorder}>
                    {order.created_at}
                    </TableCell>
                    <TableCell className={classes.columnBorder}>
                      {order.name}
                      <Typography
                        variant="caption"
                        display="block"
                        color="textSecondary"
                      >
                        {order.email}
                      </Typography>
                    </TableCell>
                    <TableCell  align={"center"} className={classes.columnBorder}>
                      {order.no_of_items}
                    </TableCell>
                    <TableCell
                      align={"center"}
                      className={classes.columnBorder}
                    >
                      {order.payment_type}
                    </TableCell>
                    <TableCell align={"center"}>{order.amount}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
  );
}
