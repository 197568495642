import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { UserNav } from "../../components/users/user-nav.component";
import UserEditForm from "../../components/users/user-edit-form.component";
import Progress from '../../components/common/progress.component';


class UserDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {}
  };
  }

  componentDidMount = () => {

    const { match: { params } } = this.props;

    if(params.user_id !== undefined) {
      axios
      .get(`/auth/users/${params.user_id}`)
      .then((response) => {
        if(response.data.status === 'success') {
          this.setState({
            user: response.data.data,
          });
        } 
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  render() {
    return (
      <div>
        <UserNav />
        { Object.keys(this.state.user).length > 0 ? <UserEditForm user={this.state.user} /> : <Progress /> }
      </div>
    );
  }
}

export default UserDetailsPage;