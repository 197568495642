import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { tinymce } from '../../config/tinymce';
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";

import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, blue } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import { fade } from "@material-ui/core/styles/colorManipulator";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Autocomplete from "@material-ui/lab/Autocomplete";

import EditIcon from "@material-ui/icons/Edit";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";

import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    maxWidth: "600px",
  },
  formName: {
    margin: theme.spacing(1),
    width: "100%",
    display: "block",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  bapro: {
    width: "200px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: fade(blue[100], 0.5),
    color: blue[800],
  },

  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ProductSchema = Yup.object().shape({
  // categories: Yup.array().required("Category is required.").min(1),
  // title: Yup.string()
  //   .min(3, "Too Short!")
  //   .max(150, "Too Long!")
  //   .required("Title is required."),
  // description: Yup.string().required("Description is required"),
});

const ProductUpdateForm = ({ categories, product }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const initialValues = {
    categories: product.categories,
    title: product.title,
    standfirst: product.standfirst,
    description: product.description,
    featured: product.featured,
    status: product.status,
    meta_description: product.meta_description,
    meta_keywords: product.meta_keywords,
  };

  return (
    <Card style={{ maxWidth: "600px" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={ProductSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          console.log(values);

          axios
            .put(`products/${product.id}`, values)
            .then((response) => {
              console.log(response);

              setLoading(false);
              actions.setSubmitting(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }
            })
            .catch(function (error) {
              console.log(error.response);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <CardHeader
              subheader="Update product details here"
              title="Product Details"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    id="categories"
                    name="categories"
                    multiple
                    options={categories}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={values.categories}
                    onChange={(e, value) => {
                      let categories_id = value.map((item) => item.id);
                      setFieldValue(
                        "categories",
                        value !== null ? value : values.categories
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        name="categories"
                        label="Product Categories"
                        placeholder="Add Category"
                        helperText={errors ? errors.categories : ""}
                        error={
                          errors && errors.categories && touched.categories
                            ? true
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="title"
                      label="Title"
                      value={values.title}
                      helperText={errors ? errors.title : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.title && touched.title ? true : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="standfirst"
                      label="Standfirst"
                      value={values.standfirst}
                      helperText={errors ? errors.standfirst : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.standfirst && touched.standfirst
                          ? true
                          : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <Editor
                      apiKey="1kpkwo9hnwqzfgbll0i17r140exrbx0liebwvsfyv8xuwvgk"
                      name="description"
                      value={values.description}
                      init={tinymce}
                      helperText={errors ? errors.description : ""}
                      onEditorChange={(e) => {
                        handleChange({
                          target: { name: "description", value: e },
                        });
                      }}
                      onBlur={handleBlur}
                      error={
                        errors && errors.description && touched.description
                          ? true
                          : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <Autocomplete
                      id="status"
                      name="status"
                      value={values.status}
                      options={["draft", "published"]}
                      onChange={(e, value) => {
                        setFieldValue("status", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          helperText={errors ? errors.status : ""}
                          onBlur={handleBlur}
                          error={
                            errors && errors.status && touched.status
                              ? true
                              : null
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <Checkbox
                        checked={Number(values.featured) === 1 ? true : false}
                        value={values.featured}
                        onChange={(e, value) => {
                          setFieldValue(
                            "featured",
                            Number(values.featured) === 1 ? 0 : 1
                          );
                        }}
                        id="featured"
                        name="featured"
                        color="primary"
                      />
                    }
                    label="This is a featured product"
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="meta_description"
                      label="Meta Description"
                      value={values.meta_description}
                      helperText={errors ? errors.meta_description : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      rows={4}
                      error={
                        errors &&
                        errors.meta_description &&
                        touched.meta_description
                          ? true
                          : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="meta_keywords"
                      label="Meta Keywords"
                      value={values.meta_keywords}
                      helperText={errors ? errors.meta_keywords : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      rows={4}
                      error={
                        errors && errors.meta_keywords && touched.meta_keywords
                          ? true
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={handleSubmit}
                  //  className={classes.submitButton}
                  startIcon={<EditIcon />}
                >
                  {isSubmitting ? "Updating ..." : "Update"}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default ProductUpdateForm;
