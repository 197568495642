import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { CategoryNav } from "../../components/categories/category-nav.component";
import CategoryUpdateForm from "../../components/categories/category-update-form.component";
import CircularProgress from "../../components/common/circular-progress.component";
import CategoryProductList from "../../components/categories/category-product-list.component";

class CategoryDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {},
    };
  }

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;

    if (params.category_id !== undefined) {
      axios
        .get(`categories/${params.category_id}`)
        .then((response) => {
          this.setState({
            ...this.state,
            category: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    if (Object.keys(this.state.category).length > 0) {

      console.log(this.state.category);

      return (
        <React.Fragment>
          <CategoryNav />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CategoryUpdateForm category={this.state.category} />
            </Grid>
            <Grid item xs={12} md={6}>
              <CategoryProductList products={this.state.category.products} />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default CategoryDetailsPage;
