import React from "react";
import Chart from "react-google-charts";

const PieChart = ({ products }) => {
  console.log(products);

let data = [
  ['Product', 'No of orders in last month'],
];

if(products.item_orders.length > 0) {
  products.item_orders.map((item, item_index) => {
      return data.push([item['title'], item['no_of_orders']]);
  });
}


    return data.length > 1 ? <Chart
    width={'800px'}
    height={'300px'}
    chartType="PieChart"
    //loader={<div>Loading Chart</div>}
    data={data}
    options={{
      title: 'Popular Products from '+products.start+ ' to '+products.end,
      // Just add this option
      is3D: true,
    }}
    rootProps={{ 'data-testid': '2' }}
  /> : '';
}

export default PieChart;

