import React from 'react';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import SkeletonCard from '../../components/common/skeleton.component';
import ShippingZoneDetails from '../../components/shipping/zones/shipping-zone-details.component';




const ShippingZoneDetilsPage = ({ match }) => {
  
  const { zone_id } = match.params;

    return (
          <ShippingZoneDetails zoneId={zone_id} />
    );
  } 

export default ShippingZoneDetilsPage;
