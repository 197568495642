import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import AddIcon from "@material-ui/icons/Add";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import { useSnackbar } from "notistack";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    marginBottom: theme.spacing(1.5),
  },
  boxButton: {
    maxWidth: theme.spacing(24),
    height: theme.spacing(16),
    padding: theme.spacing(2),
    transition: "0.5s all ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer",
      color: theme.palette.info.main,
      boxShadow: `0 8px 6px -6px ${theme.palette.info.light}`,
    },
  },
  boxIcon: {
    display: "block",
    fontSize: 56,
    margin: "4px auto",
  },
  boxTitle: {
    textAlign: "center",
    fontSize: 16,
    margin: "8px auto",
  },

  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addButton: {
    color: theme.palette.info.light,
  },
  redText: {
    color: red[800],
  },
}));

const ShippingOptionSchema = Yup.object().shape({
  provider: Yup.string().required("Provider is required."),
  service: Yup.string().required("Service is required."),
  speed: Yup.string().required("Speed is required."),
  tracking_type: Yup.string().when("has_tracking", {
    is: (value) => value && value === 1,
    then: Yup.string()
      .required("Tracking type is required.")
      .typeError("Tracking type is required."),
    otherwise: Yup.string(),
  }),
  min_weight: Yup.number().typeError("Minimum weight from must be a number."),
  max_weight: Yup.number()
    .positive()
    .typeError("Maximum weight from must be a positive number."),
});

export default function ShippingOptionAddForm({
  addShippingOption,
  boxButton,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [hasTracking, setHasTracking] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    provider: "",
    service: "",
    speed: "",
    has_tracking: 0,
    tracking_type: "",
    min_weight: "",
    max_weight: "",
    available: 1,
    note: "",
  };

  return (
    <React.Fragment>
      {boxButton === true ? (
        <Paper
          elevation={1}
          className={classes.boxButton}
          onClick={handleClickOpen}
        >
          <AddIcon className={classes.boxIcon} />
          <Typography component="h6" className={classes.boxTitle}>
            Add Shipping Option
          </Typography>
        </Paper>
      ) : (
        <IconButton
          size="small"
          aria-label="add"
          className={classes.addButton}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </IconButton>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={ShippingOptionSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          axios
            .post(`shipping-options`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);
              actions.resetForm(true);
              setOpen(false);

              if (response.data && response.data.status) {
                enqueueSnackbar(`${response.data.message}`, {
                  variant: `${response.data.status}`,
                });
              }

              if (response.data && response.data.status === "success") {
                addShippingOption(response.data.data);
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Add New Shipping Option
            </DialogTitle>

            <DialogContent dividers={true}>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="provider"
                  label="Provider"
                  type="text"
                  value={values.provider}
                  helperText={errors ? errors.provider : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.provider && touched.provider ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="service"
                  label="Service"
                  type="text"
                  value={values.service}
                  helperText={errors ? errors.service : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.service && touched.service ? true : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="speed"
                  label="Speed"
                  type="text"
                  value={values.speed}
                  helperText={errors ? errors.speed : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.speed && touched.speed ? true : null}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.has_tracking === 1 ? true : false}
                      onChange={(e, value) => {
                        setFieldValue("has_tracking", value ? 1 : 0);
                        setHasTracking(value ? 1 : 0);
                      }}
                      name="has_tracking"
                      color="primary"
                    />
                  }
                  label="Has tracking"
                />
              </FormControl>

              {hasTracking === 1 ? (
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    name="tracking_type"
                    label="Tracking Type"
                    type="text"
                    value={values.tracking_type}
                    helperText={errors ? errors.tracking_type : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors && errors.tracking_type && touched.tracking_type
                        ? true
                        : null
                    }
                  />
                </FormControl>
              ) : (
                ""
              )}

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="min_weight"
                  label="Min Weight (g)"
                  type="text"
                  value={values.min_weight}
                  helperText={errors ? errors.min_weight : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.min_weight && touched.min_weight
                      ? true
                      : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name="max_weight"
                  label="Max Weight (g)"
                  type="text"
                  value={values.max_weight}
                  helperText={errors ? errors.max_weight : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors && errors.max_weight && touched.max_weight
                      ? true
                      : null
                  }
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.available === 1 ? true : false}
                      onChange={(e, value) => {
                        setFieldValue("available", value ? 1 : 0);
                      }}
                      name="available"
                      color="primary"
                    />
                  }
                  label="Available"
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  multiline
                  rowsMax={4}
                  name="note"
                  label="Note"
                  type="text"
                  value={values.note}
                  helperText={errors ? errors.note : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors && errors.note && touched.note ? true : null}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>

              <Button
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={handleSubmit}
                startIcon={
                  loading ? <CircularProgress size={16} /> : <SaveIcon />
                }
              >
                {isSubmitting ? "Saving ..." : "Save"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
