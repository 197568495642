
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const signin = async (formProps, callback) => {

	localStorage.removeItem('user');
	localStorage.removeItem('access_token');
	localStorage.removeItem('authenticated');
	localStorage.clear();

	console.log('cleared local storage');

	await axios.post(API_URL+'auth/login', {
		email: formProps.email,
		password: formProps.password,
	})
	.then(response => {
		localStorage.setItem('access_token', response.data.access_token);
		localStorage.setItem('authenticated', true);
		console.log('set token and auth');
		console.log(response.data.access_token);

		axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;

		return axios.get(API_URL+'auth/me');
	})
	.then(response => {
		if(response.data.admin === 1) {
			localStorage.setItem('user', JSON.stringify(response.data));
			console.log('set auth user');
			callback();
		} else {
			localStorage.removeItem('user');
			localStorage.removeItem('access_token');
			localStorage.removeItem('authenticated');
			console.log('cleared local storage');
		}
	})
	.catch(error => {
		console.log(error.response);
		return error.response;
	});
};

export const signout = () => {

	localStorage.removeItem('user');
	localStorage.removeItem('access_token');
	localStorage.removeItem('authenticated');
	localStorage.clear();
	console.log('cleared local storage');
};

export const auth = () => {
    return {
		user: localStorage.getItem('user') ?? {},
        access_token: localStorage.getItem('access_token') ?? null,
        authenticated: localStorage.getItem('authenticated') ?? false,
    }
}

