import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';

import Button from '@material-ui/core/Button';

import { red, grey } from "@material-ui/core/colors";

import ImageUploadForm from './image-upload-form.component';
import ImageEditForm from './image-edit-form.component';
import ImageDeleteForm from './image-delete-form.component';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: '100%',
  },

  columnBorder: {
    borderLeft: `1px solid${grey[300]}`,
  },
    }));


export default function ProductImages({ productId, images }) {
  const classes = useStyles();
const [productImages, setProductImages] = React.useState(images);

const [test, setTest] = React.useState('');

console.log(productImages);


  const addNewImage = (image) => {
productImages.push(image);

setProductImages(productImages.map((item) => item));

  }

  const updateImage = (image) => {

    setProductImages(productImages.map(item => item.id !== image.id ? item : image));

  }

  const removeImage = (imageId) => {

    setProductImages(productImages.filter((item) => item.id !== imageId));

  }

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="16%">Picture</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell width="40%" align="center" className={classes.columnBorder}>
            

<ImageUploadForm productId={productId} addNewImage={addNewImage} />

            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productImages.map((image) => (
            <TableRow key={image.id}>
              <TableCell width="16%" component="th" scope="row">
              <Avatar alt="P" src={`${image.location}`} className={classes.large} />
              </TableCell>
              <TableCell align="left">{image.description}</TableCell>
              <TableCell width="40%" align="center" className={classes.columnBorder}>
<div>
<ImageEditForm image={image} updateImage={updateImage} />
              <ImageDeleteForm image={image} removeImage={removeImage} />
</div>
             

      

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
