import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { grey } from "@material-ui/core/colors";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import PublicIcon from "@material-ui/icons/Public";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";

import ShippingZoneAddForm from "./shipping-zone-add-form.component";
import ShippingZoneEditForm from "./shipping-zone-edit-form.component";
import ShippingZoneDeleteForm from "./shipping-zone-delete-form.component";
import ShippingCountriesForm from "./shipping-countries.component";
import SkeletonCard from "../../../components/common/skeleton.component";

import { ShippingZonesNav } from '../shipping-nav.component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  container: {
  //  maxHeight: 440,
  },
  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid${grey[300]}`,
  },
  hoverPointer: {
    cursor: "pointer",
    borderRight: `1px solid${grey[300]}`,
  },

  
}));

export default function ShippingZones() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [shippingZones, setShippingZones] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    axios
      .get(`shipping-zones`)
      .then((response) => {
        setShippingZones(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const addShippingZone = (zone) => {
    shippingZones.push(zone);
    setShippingZones(shippingZones.map((item) => item));
  };

  const updateShippingZone = (zone) => {
    setShippingZones(
      shippingZones.map((item) => (item.id !== zone.id ? item : zone))
    );
  };

  const removeShippingZone = (zoneId) => {
    setShippingZones(shippingZones.filter((item) => item.id !== zoneId));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return loading ? (
    <SkeletonCard message="Loading shipping zones ..." />
  ) : (
    <React.Fragment>
       
       <ShippingZonesNav />
    <Paper className={classes.root}>


      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align={"center"}
                width="24%"
                className={classes.tableHead}
              >
                Shipping Zone
              </TableCell>
              <TableCell
                align={"center"}
                width="10%"
                className={classes.tableHead}
              >
                Countries
              </TableCell>
              <TableCell
                align={"center"}
                width="20%"
                className={classes.tableHead}
              >
                No of Rates
              </TableCell>
              
              <TableCell
                align={"center"}
                width="10%"
                className={classes.tableHead}
              >
                Available
              </TableCell>
             
            </TableRow>
          </TableHead>
          <TableBody>
            {shippingZones.map((zone) => (
              <TableRow hover tabIndex={-1} key={zone.id}>
                <TableCell width="24%" align={"center"} className={classes.columnBorder}>
                  <Tooltip
                    title={
                      zone.no_of_rates > 0
                        ? `${zone.no_of_rates} rates available`
                        : ""
                    }
                    placement="top-end"
                  >
                    <Chip
                      avatar={<PublicIcon />}
                      label={`${zone.title}`}
                      onClick={() =>
                        history.push(
                          "/settings/shipping-zones/" + zone.id + "/details"
                        )
                      }
                    />
                  </Tooltip>
                </TableCell>

                <TableCell
                  align={"center"}
                  width="10%"
                  className={classes.columnBorder}
                >
                  {zone.no_of_countries}
                </TableCell>

                <TableCell
                  align={"center"}
                  width="20%"
                  className={classes.columnBorder}
                >
                  {zone.no_of_rates}
                </TableCell>

                <TableCell width="10%" align={"center"} className={classes.columnBorder}>
                  {zone.available === 1 ? (
                    <CheckIcon color="primary" fontSize="small" />
                  ) : (
                    <CloseIcon color="secondary" fontSize="small" />
                  )}
                </TableCell>
               
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    <ShippingZoneAddForm addShippingZone={addShippingZone} />
    </React.Fragment>
  );
}
