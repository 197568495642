import React from 'react';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import ShippingZones from '../../components/shipping/zones/shipping-zones.component';

const ShippingZonesPage = () => {
  
    return (
          <ShippingZones />
    );
  } 

export default ShippingZonesPage;
