import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#292627',
    },
    info: {
      main: '#e2a03c',
    }
  }
});

export default theme;
