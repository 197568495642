import React from 'react';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PeopleIcon from '@material-ui/icons/People';
import CategoryIcon from '@material-ui/icons/Category';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 260,
  },

  nested: {
    padding: 0,
    margin: 0,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      
    }
  },

  nestedText: {
    paddingLeft: '25px',
    fontSize: '1rem',
    borderLeft: `2px solid ${theme.palette.info.dark}`,
    lineHeight: '32px',
    margin: 0,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    transition: '0.2s all ease-in-out',
    '&:hover': {
      paddingLeft: '27px',
    }
  },

  nestedIcon: {
    color: theme.palette.info.dark,
    width: '16px',
    marginRight: '-16px',
    marginLeft: '25px',
  },

  nestedFocus: {
    color: theme.palette.grey[300],
    borderLeft: `2px solid ${theme.palette.grey[300]}`,
  },

  item: {
    fontFamily: ["Heebo", "sans-serif"].join(','),
    fontSize: '1.0rem',
    fontWeight: 500,
    paddingLeft: '20px',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    transition: '0.2s all ease-in-out',
   '&:hover': {
     backgroundColor: fade(theme.palette.primary.dark, 0.7),
     paddingLeft: '22px',
   },
  },

  itemText: {
    paddingLeft: '6px',

    
  },

  icon: {
    color: theme.palette.info.light,
  },

  iconSpace: {
    minWidth: '32px',
  },

  openIconSpace: {
    minWidth: '48px',
  },

  hide: {
    display: 'none'
  },


  listSubheader: {
    display: 'block',
    color: theme.palette.info.main,
    backgroundColor: theme.palette.primary.main, //fade('rgba(37, 48, 83, 1)', 0.9),
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    paddingTop: '0px',
    paddingBottom: '0px',
    borderTop: `1px solid ${fade(theme.palette.primary.dark, 0.6)}`,
    borderBottom: `1px solid ${fade(theme.palette.primary.dark, 0.5)}`,
  },

  focus: {
    color: theme.palette.grey[300]
  },

}));

export default function PrimaryList({ more }) {
  const classes = useStyles();
  const [toggleList, setToggleList] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const currentLocation = window.location.pathname;
  const history = useHistory();

  const handleClick = list_id => {
    setOpen(!open);
    toggleList[list_id] = !toggleList[list_id];
    setToggleList(toggleList);

    console.log(toggleList);
  };


  const dashboard = {
    id: "dashboard",
    name: 'Dashboard',
    path: '/dashboard',
    icon: {
      more: <DashboardIcon className={clsx(classes.icon, (currentLocation === '/dashboard') && classes.focus)} />,
      less: <Tooltip title="Dashboard" placement="right-start" onClick={() => history.push('/dashboard')}>
        <DashboardIcon className={clsx(classes.icon, toggleList['dashboard'] ? classes.focus : '')} />
      </Tooltip>,
    }
  }

  const orders = {
    id: "orders",
    name: 'Orders',
    path: '/orders',
    icon: {
      more: <ShoppingBasketIcon className={clsx(classes.icon, (currentLocation === '/orders') && classes.focus)} />,
      less: <Tooltip title="Orders" placement="right-start" onClick={() => history.push('/orders')}>
        <ShoppingBasketIcon className={clsx(classes.icon, toggleList['orders'] ? classes.focus : '')} />
      </Tooltip>,
    }
  }


  const customers = {
    id: "customers",
    name: 'Customers',
    path: '/customers',
    icon: {
      more: <PeopleIcon className={clsx(classes.icon, (currentLocation === '/customers') && classes.focus)} />,
      less: <Tooltip title="Customers" placement="right-start" onClick={() => history.push('/customers')}>
        <PeopleIcon className={clsx(classes.icon, toggleList['customers'] ? classes.focus : '')} />
      </Tooltip>,
    }
  }

  const categories = {
    id: "categories",
    name: 'Categories',
    path: '/categories',
    icon: {
      more: <CategoryIcon className={clsx(classes.icon, (currentLocation === '/categories') && classes.focus)} />,
      less: <Tooltip title="Categories" placement="right-start" onClick={() => history.push('/categories')}>
        <CategoryIcon className={clsx(classes.icon, toggleList['categories'] ? classes.focus : '')} />
      </Tooltip>,
    }
  }

  const products = {
    id: "products",
    name: 'Products',
    path: '/products',
    icon: {
      more: <ListAltIcon className={clsx(classes.icon, (currentLocation === '/products') && classes.focus)} />,
      less: <Tooltip title="Products" placement="right-start" onClick={() => history.push('/products')}>
        <ListAltIcon className={clsx(classes.icon, toggleList['products'] ? classes.focus : '')} />
      </Tooltip>,
    }
  }

  const shipping = {
    id: "shipping",
    name: 'Shipping',
    path: '/settings/shippings',
    icon: {
      more: <LocalShippingIcon className={clsx(classes.icon, (currentLocation === '/settings/shippings') && classes.focus)} />,
      less: <Tooltip title="Shipping" placement="right-start" onClick={() => history.push('/settings/shippings')}>
        <LocalShippingIcon className={clsx(classes.icon, toggleList['shipping'] ? classes.focus : '')} />
      </Tooltip>,
    }
  }


  const users = {
    id: "users",
    name: 'Users',
    path: '/settings/users',
    icon: {
      more: <VerifiedUserIcon className={clsx(classes.icon, (currentLocation === '/settings/users') && classes.focus)} />,
      less: <Tooltip title="Users" placement="right-start" onClick={() => history.push('/settings/users')}>
        <VerifiedUserIcon className={clsx(classes.icon, toggleList['users'] ? classes.focus : '')} />
      </Tooltip>,
    }
  }

  const renderItem = (item, more) => {
    return <ListItem onClick={() => handleClick(item.id)} button component={Link} to={ item.path } className={classes.item}>
      <ListItemIcon className={more ? classes.iconSpace : classes.openIconSpace}>
        { more ? item.icon.more : item.icon.less }
      </ListItemIcon>
      <ListItemText primary={ item.name } disableTypography={true} className={clsx(classes.itemText, (currentLocation === item.path)? classes.focus : '')} />
    </ListItem>
  }

  return (
    <React.Fragment>

<List
      component="div" disablePadding
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="span"
          id="nested-list-subheader"
          className={more ? classes.listSubheader : classes.hide}
        >
          Shop
        </ListSubheader>
      }
      className={classes.root}
    >

{renderItem(dashboard, more)}
{renderItem(orders, more)}
{/* {renderItem(customers, more)} */}

    </List>

    <List
      component="div" disablePadding
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="span"
          id="nested-list-subheader"
          className={more ? classes.listSubheader : classes.hide}
        >
          Store
        </ListSubheader>
      }
      className={classes.root}
    >
{renderItem(categories, more)}
{renderItem(products, more)}

    </List>

    <List
      component="div" disablePadding
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="span"
          id="nested-list-subheader"
          className={more ? classes.listSubheader : classes.hide}
        >
          Setting
        </ListSubheader>
      }
      className={classes.root}
    >
      { renderItem(shipping, more) }
       { renderItem(users, more) }
  

    </List>

    </React.Fragment>
   
  );
}