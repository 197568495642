import React, { useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";

import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    maxWidth: "600px",
  },
  formName: {
    margin: theme.spacing(1),
    width: "100%",
    display: "block",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  formControl: {
    width: "100%",
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("First name is required."),
  email: Yup.string().email().required("Email Address is required"),
});

const UserEditForm = ({ user }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    name: user.name,
    email: user.email,
    disabled: user.disabled,
  };

  return (
    <Card className={classes.card}>
      <Formik
        initialValues={initialValues}
        validationSchema={UserSchema}
        onSubmit={(values, actions) => {
          setLoading(true);

          axios
            .put(`auth/users/${user.id}`, values)
            .then((response) => {
              console.log(response);
              setLoading(false);
              actions.setSubmitting(false);

              enqueueSnackbar(`User details successfully updated.`, {
                variant: "success",
              });
            })
            .catch(function (error) {
              console.log(error.response);
              setLoading(false);
              actions.setErrors(error.response.data.errors);
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form noValidate autoComplete="off">
            <CardHeader subheader="Update details here" title="User Details" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="name"
                      label="Name"
                      value={values.name}
                      helperText={errors ? errors.name : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.name && touched.name ? true : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      name="email"
                      label="Email Address"
                      value={values.email}
                      helperText={errors ? errors.email : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors && errors.email && touched.email ? true : null
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.disabled}
                        onChange={handleChange}
                        name="disabled"
                      />
                    }
                    label="Disable"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={handleSubmit}
                  startIcon={<SaveIcon />}
                >
                  {isSubmitting ? "Updating ..." : "Update"}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default UserEditForm;
