import React from "react";
import { useHistory } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { grey } from "@material-ui/core/colors";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },

  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    hover: {
      cursor: "pointer",
    },
  },
  columnBorder: {
    borderRight: `1px solid ${grey[300]}`,
  },
}));

export default function ProductList({ products }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} colSpan={2}>
                Product Title
              </TableCell>
              <TableCell align={"center"} className={classes.tableHead}>
                No of Variants
              </TableCell>
              <TableCell align={"center"} className={classes.tableHead}>
                Minimum Price
              </TableCell>
              <TableCell align={"center"} className={classes.tableHead}>
                Stock
              </TableCell>
              <TableCell align={"center"} className={classes.tableHead}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product) => {
                return (
                  <TableRow
                    hover
                    style={{ cursor: "pointer" }}
                    role="checkbox"
                    tabIndex={-1}
                    key={product.id}
                    onClick={() =>
                      history.push("/products/" + product.id + "/details")
                    }
                  >
                    <TableCell align={"center"} width="5%">
                      {product.feature_image_location === null ? (
                        <Avatar>
                          <CropOriginalIcon />
                        </Avatar>
                      ) : (
                        <Avatar
                          alt={product.feature_image_description}
                          src={product.feature_image_location}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.columnBorder}>
                      {product.title}
                    </TableCell>
                    <TableCell
                      align={"center"}
                      className={classes.columnBorder}
                    >
                      {product.no_of_variants}
                    </TableCell>
                    <TableCell
                      align={"center"}
                      className={classes.columnBorder}
                    >
                      {Number(product.price_from)
                        ? "£" + product.price_from
                        : "--"}
                    </TableCell>
                    <TableCell
                      align={"center"}
                      className={classes.columnBorder}
                    >
                      {product.stock}
                    </TableCell>
                    <TableCell align={"center"}>
                      {product.status === "draft" ? (
                        <Chip size="small" label="DRAFT" color="secondary" />
                      ) : (
                        <Chip
                          size="small"
                          label={product.status.toUpperCase()}
                          color="primary"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
