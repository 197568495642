import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FeatureImageUploadForm from './feature-image-upload-form.component';
import FeatureImageEditForm from './feature-image-edit-form.component';
import FeatureImageDeleteForm from './feature-image-delete-form.component';



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '240px',
    // backgroundColor: 'blue',
  },

  root2: {
    maxWidth: 345,
  },
  media: {
    height: 120,
  },

  input: {
    display: "none",
  },

  button: {
    zIndex: 100000,
    margin: theme.spacing(1),
    position: "absolute",
    top: 0,
    right: 0,
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));


export default function ProductFeatureImage({ productId, featureImage }) {
  const classes = useStyles();

  //https://raw.githubusercontent.com/dxyang/StyleTransfer/master/style_imgs/mosaic.jpg


  const [showFrom, setShowFrom] = React.useState(false);

  const [imageData, setImageData] = React.useState(featureImage); 

  const setImage = (image) => {

    
    
    setImageData(image);
    
    // console.log('addnew');
    // console.log(productImages);
    // setTest('tet');
    
      }

    
      const removeImage = () => {
        setImageData(null);
      }

  if (imageData === null) {
    return (
        <div>
      <Card className={classes.root}>
        <CardHeader title="Feature Image" subheader="Add feature image here" action={
          <FeatureImageUploadForm productId={productId} setImage={setImage} />
        }/>
        <Skeleton variant="rect" height={120} />
        <CardContent>
          
          <Box pt={0.5}>
            <Skeleton />
            <Skeleton />
            <Skeleton width="60%" />
          </Box>
        </CardContent>
      </Card>
</div>
    );
  }

  return (
    <div>
      
    <Card className={classes.root}>
      
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={imageData.location}
          title="Contemplative Reptile"
          style={{height: '200px'}}
        />
        <CardContent>
   
          <Typography variant="body2" color="textSecondary" component="p">
          {imageData.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
       <FeatureImageEditForm image={imageData} setImage={setImage} />
       <FeatureImageDeleteForm image={imageData} removeImage={removeImage} />
      </CardActions>
    </Card>
    </div>
  );
}
