import React from 'react';
import { useHistory } from "react-router-dom";
import { fade, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import { red, grey } from "@material-ui/core/colors";

const columns = [
  { id: 'title', label: 'Title', width: '60%' },
  { id: 'no_of_products', label: 'No of Products', width: '20%', align: 'center' },
  { id: 'status', label: 'Status', width: '20%', align: 'center' }
];


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },

  tableHead: {
    backgroundColor: fade(theme.palette.primary.dark, 0.85),
    color: theme.palette.info.light,
  },
  tableRow: {
    'hover': {
      cursor: 'pointer'
    }
  },
  columnBorder: {
    borderRight: `1px solid${grey[300]}`,
  },
}));

export default function CategoryList({ categories }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead >
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableHead}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) => {

              return (

                <TableRow hover style={{ cursor: 'pointer' }} role="checkbox" tabIndex={-1} key={category.id} onClick={() => history.push('/categories/' + category.id + '/details')}>
                  <TableCell className={classes.columnBorder}>{category.title}</TableCell>
                  <TableCell align={'center'} className={classes.columnBorder}>{category.no_of_products}</TableCell>
                  <TableCell align={'center'} className={classes.columnBorder}>
                    {category.status === 'draft' ? <Chip
                      size="small"
                      label="DRAFT"
                      color="secondary"
                    /> : <Chip
                        size="small"
                        label={category.status.toUpperCase()}
                        color="primary"
                      />}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={categories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
