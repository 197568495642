import React, { Component } from "react";

import axios from "axios";
import { CategoryNav } from "../../components/categories/category-nav.component";
import CategoryUpdateForm from "../../components/categories/category-update-form.component";
import CircularProgress from "../../components/common/circular-progress.component";
import CategoryProductList from "../../components/categories/category-product-list.component";

import OrderDetails from '../../components/orders/order-details.component';

const OrderDetailsPage = ({match}) =>  {

  const { order_id } = match.params;

      return (
        <OrderDetails orderId={order_id} />
       
      );
}

export default OrderDetailsPage;
