import React, { Component } from "react";
import axios from "axios";
import { CategoryListNav } from "../../components/categories/category-nav.component";
import CategoryList from "../../components/categories/category-list.component";
import CircularProgress from "../../components/common/circular-progress.component";

class CategoryListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
    };
  }

  componentDidMount = () => {
    axios
      .get(`categories`)
      .then((response) => {
        this.setState({
          categories: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (Object.keys(this.state.categories).length > 0) {
      return (
        <div>
          <CategoryListNav />
          <CategoryList categories={this.state.categories} />
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default CategoryListPage;
