import React from 'react';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import ShippingOptions from '../../components/shipping/options/shipping-options.component';

const ShippingOptionsPage = () => {
    return (
          <ShippingOptions />
    );
  } 

export default ShippingOptionsPage;
