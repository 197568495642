import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { red, grey, green } from "@material-ui/core/colors";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles({
  root: {
    marginTop: 16,
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  container: {
    marginTop: 8,
    border: `1px solid ${grey[300]}`,
  },
  table: {
    minWidth: "100%",
  },
  borderRight: {
    borderRight: `1px solid ${grey[300]}`,
  },

  doneIcon: {
    color: green[600],
  },
  closeIcon: {
    color: red[600],
  },
});

export default function OrderPaymentDetails({ payment }) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container} variant={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              <div>Billing Details:</div>
              <div>Name: {payment.billing_details.name}</div>
              {payment.billing_details.email !== "" ? (
                <div>Email Address: {payment.billing_details.email}</div>
              ) : (
                ""
              )}
              <div>Address:</div>
              {payment.billing_details.address_line_1 !== "" ? (
                <div>{payment.billing_details.address_line_1}</div>
              ) : (
                ""
              )}
              {payment.billing_details.address_line_2 !== "" ? (
                <div>{payment.billing_details.address_line_2}</div>
              ) : (
                ""
              )}
              {payment.billing_details.city !== "" ? (
                <div>{payment.billing_details.city}</div>
              ) : (
                ""
              )}
              {payment.billing_details.county !== "" ? (
                <div>{payment.billing_details.county}</div>
              ) : (
                ""
              )}
              {payment.billing_details.postcode !== "" ? (
                <div>{payment.billing_details.postcode}</div>
              ) : (
                ""
              )}
              {payment.billing_details.country_code !== "" ? (
                <div>{payment.billing_details.country_code}</div>
              ) : (
                ""
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="right" className={classes.borderRight}>
              Total order amount:{" "}
            </TableCell>
            <TableCell align="right">£{payment.amount}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="right" className={classes.borderRight}>
              Payment type:{" "}
            </TableCell>
            <TableCell align="right">
              {" "}
              <Chip
                label={payment.payment_type}
                color="primary"
                variant="outlined"
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="right" className={classes.borderRight}>
              Payment confirmed:{" "}
            </TableCell>
            <TableCell align="right">
              {payment.payment_confirmed ? (
                <DoneIcon className={classes.doneIcon} />
              ) : (
                <ClearIcon className={classes.closeIcon} />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
