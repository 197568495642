import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    color: 'red',
  }
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src="https://media.giphy.com/media/nVTa8D8zJUc2A/giphy.gif" alt="Not found"/>
    </div>
  );
};

export default NotFound;
